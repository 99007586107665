import { React, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Eye, EyeClose, Tick } from "../../../constants/svg";
import { Assets } from "../../../constants/images";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router";
// import {FaEye, FaEyeSlash} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../../../constants/app-constants"


const EditUser = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = data => console.log(data);
  return (
    <div className="user-modal validation-margin">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter email"
          {...register("email",
          {
              maxLength: {
                  value: VALIDATIONS.EMAIL,
                  message: VALIDATIONS_TEXT.EMAIL_MAX
              },
              required: {
                  value: true,
                  message: VALIDATIONS_TEXT.EMAIL_REQUIRED
              },
              pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: VALIDATIONS_TEXT.EMAIL_FORMAT
              }
          })
      }                          />
      {
errors.email && 
<span className="validations">{ errors.email.message}</span>
}
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Password"
                                      {...register("password", {
                                        minLength: {
                                          value: 5,
                                          message: "Min Length of password is 5",
                                        },
                                        maxLength: {
                                          value: 20,
                                          message: "Max Length of password is 20",
                                        },
                                        required: {
                                          value: true,
                                          message: "Password is required",
                                        },
                                      })}
                                    />
                                                     {
                                    errors.password && 
                                    <span className="validations">{ errors.password.message}</span>
                                   }
        </Form.Group>

        <Form.Group>
          <Form.Label>Role</Form.Label>
            <Form.Select aria-label="Default select example">
              <option>Care Provider</option>
              <option value="1">One</option>
              <option value="2">Medical provider</option>
              <option value="3">Health Provider</option>
            </Form.Select>
        </Form.Group>
        <div className="text-end">
        <Button className="modal_submit_btn" type="submit">
          Update
        </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditUser;
