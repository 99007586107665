import { React, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../../components/StackedChart";
import { DataGrid } from "@mui/x-data-grid";
import MultiRangeSlider from "multi-range-slider-react";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../../../constants/app-constants";
import { useForm } from "react-hook-form";


const TermsModal = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = data => console.log(data);
  return (
    <div className="user-modal">
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Terms and Conditions</Form.Label>
        <Form.Control as="textarea" rows={15}
                              {...register("terms", {
                                minLength: {
                                  value: 2,
                                  message: "Name is very short",
                                },
                                maxLength: {
                                  value: VALIDATIONS.ROLE_NAME,
                                  message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                                },
                                required: {
                                  value: true,
                                  message: "Terms and Condition is required",
                                },
                              })}
                            />
                            {errors.terms && <span className="validations">{errors.terms.message}</span>}
      </Form.Group>
      <div className="text-end">
        <Button className="modal_submit_btn" variant="primary" type="submit">
          Update
        </Button>
      </div>
    </Form>
    </div>
  )
}

export default TermsModal