import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  plugins: {
    legend: {
      position: 'bottom' ,
    },
    title: {
      display: false,
    },
  },
};

const labels = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

export const data = {
  labels,
  datasets: [
    {
      label: 'ENT',
      data: [21, 10, 20, 30, 25, 35, 30],
      backgroundColor: '#A284E8',
      borderRadius: 5,
      barPercentage: 0.4,
      categoryPercentage: 0.7,




    },
    {
      label: 'Dental',
      data: [42, 10, 20, 30, 25, 35, 30],
      backgroundColor: '#5FBF24',
      barPercentage: 0.4,
      categoryPercentage: 0.7,


    },    {
      label: 'Eye',
      data: [42, 10, 20, 30, 25, 35, 30],
      backgroundColor: '#29BFAA',
      barPercentage: 0.4,
      categoryPercentage: 0.7,


    },    {
      label: 'Heart',
      data: [42, 10, 20, 30, 25, 35, 30],
      backgroundColor: '#3F8CFF',
      borderRadius: 5,
      barPercentage: 0.4,
      categoryPercentage: 0.7,



    },
  ],

};
const StackedChart = () => {
  return (
    <div>
  <Bar options={options} data={data} />


    </div>
  )
}

export default StackedChart