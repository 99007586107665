import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../../components/StackedChart";
import { DataGrid } from "@mui/x-data-grid";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
  MenuDots,
  ThreeDots,
  GreenTick,
} from "../../../constants/svg";
import { Assets } from "../../../constants/images";
import LineGraph from "../../../components/LineGraph";
import CareGraph from "../../../components/CareGraph";
import TotalPlansGraph from "../../../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../../../components/Statistics";
import DashboardSidebar from "../../../components/DashboardSidebar";
import ProfileBox from "../../../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../../../components/ResponsiveNavbar";
import ThemeModal from "../../../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../../../components/TopBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PaymentCard from "../../../components/PaymentCard";

const RenewalTracking = () => {

    const userActions = (rowData) => {
        return (
          <DropdownButton
            align="end"
            className={"actionDropdown"}
            id="dropdown-item-button"
            title={<MenuDots />}
          >
            <Dropdown.Item as="button">Edit</Dropdown.Item>
            <Dropdown.Item as="button">Delete</Dropdown.Item>
          </DropdownButton>
        );
      };
    
      const columns = [
        {
          field: "UserId",
          headerName: "User ID",
          width: 120,
          align: "center",
          headerAlign: "center",
        },
        {
          field: "membername",
          headerName: "Member Name",
          width: 120,
          align: "center",
          headerAlign: "center",
        },
        {
          field: "memberid",
          headerName: "Member ID",
          width: 120,
          align: "center",
          headerAlign: "center",
        },
        {
          headerName: "Status",
          width: 120,
          id: "action",
          name: "Action",
          renderCell: (cellValues) => {
            return (
              <div className="table-status">
                Active
            </div>
            );
          },    
    },
        {
          field: "renewalamount",
          headerName: "Renewal Amount",
          width: 120,
          align: "center",
          headerAlign: "center",
        },
        {
          field: "autorenewal",
          headerName: "Auto Renewal",
          width: 120,
          align: "center",
          headerAlign: "center",
        },
        {
          field: "renewaldate",
          headerName: "Renewal Date",
          width: 120,
          align: "center",
          headerAlign: "center",
        },
        {
          field: "pay",
          headerName: "Pay",
          width: 120,
          align: "center",
          headerAlign: "center",
        },
      ];
    
      const rows = [
        {
          id: 1,
          UserId: "01",
          membername: "Paul Doe",
          memberid: "123",
          renewalamount: "$ 566",
          autorenewal: "On",
          renewaldate: "01/12/2022",
          pay: "abc"
        },
    
        {
          id: 2,
          UserId: "02",
          membername: "Paul Doe",
          memberid: "123",
          renewalamount: "$ 566",
          autorenewal: "On",
          renewaldate: "01/12/2022",
          pay: "abc"
        },
    
        {
          id: 3,
          UserId: "03",
          membername: "Paul Doe",
          memberid: "123",
          renewalamount: "$ 566",
          autorenewal: "On",
          renewaldate: "01/12/2022",
          pay: "abc"
        },
        {
          id: 4,
          UserId: "04",
          membername: "Paul Doe",
          memberid: "123",
          renewalamount: "$ 566",
          autorenewal: "On",
          renewaldate: "01/12/2022",
          pay: "abc"
        },
        {
          id: 5,
          UserId: "05",
          membername: "Paul Doe",
          memberid: "123",
          renewalamount: "$ 566",
          autorenewal: "On",
          renewaldate: "01/12/2022",
          pay: "abc"
        },
        {
          id: 6,
          UserId: "06",
          membername: "Paul Doe",
          memberid: "123",
          renewalamount: "$ 566",
          autorenewal: "On",
          renewaldate: "01/12/2022",
          pay: "abc"
        },
        {
          id: 7,
          UserId: "07",
          membername: "Paul Doe",
          memberid: "123",
          renewalamount: "$ 566",
          autorenewal: "On",
          renewaldate: "01/12/2022",
          pay: "abc"
        },
        {
          id: 8,
          UserId: "08",
          membername: "Paul Doe",
          memberid: "123",
          renewalamount: "$ 566",
          autorenewal: "On",
          renewaldate: "01/12/2022",
          pay: "abc"
        },
        {
          id: 9,
          UserId: "09",
          membername: "Paul Doe",
          memberid: "123",
          renewalamount: "$ 566",
          autorenewal: "On",
          renewaldate: "01/12/2022",
          pay: "abc"
        },
      ];

  return (
    <>
      <div className="user-management">
        <Row>
          <Col md={12}>
            <div className="breadcrumbs">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <NavLink to="/dashboard">Dashboard</NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <NavLink to="/EzPlan/">EZ Plan Dashboard</NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <NavLink to="/EzPlan/renewal-tracking">Renewal Tracking</NavLink>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h2>Renewal Tracking</h2>
          </Col>
        </Row>
      </div>
      <div className="renewal-tracking">
        <h4>Statistics</h4>
        <Row>
          <Col md={6} lg={3}>
            <div className="d-flex">
              <div>
                <img className="img-fluid" src={Assets.RenewalIcon} />
              </div>
              <div>
                <div className="stats-heading">$ 30 - $9000</div>
                <div className="stats-desc">
                  Upcoming Renewal in next 90 Days
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} lg={3}>
            <div className="d-flex mt-3 mt-l-0">
              <div>
                <img className="img-fluid" src={Assets.RenewalIconTwo} />
              </div>
              <div>
                <div className="stats-heading">$ 30 - $9000</div>
                <div className="stats-desc">Total No. of Expired Plans</div>
              </div>
            </div>
          </Col>
          <Col md={6} lg={3}>
            <div className="d-flex mt-3 mt-l-0">
              <div>
                <img className="img-fluid" src={Assets.RenewalIconThree} />
              </div>
              <div>
                <div className="stats-heading">$ 30 - $9000</div>
                <div className="stats-desc">Total No. of Plans Renewed</div>
              </div>
            </div>
          </Col>
          <Col md={6} lg={3}>
            <div className="d-flex mt-3 mt-l-0">
              <div>
                <img className="img-fluid" src={Assets.RenewalIconFour} />
              </div>
              <div>
                <div className="stats-heading">90%</div>
                <div className="stats-desc">Renewal Rate</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
            <div className="user-management">
        <Row>
          <Col md={12}>
            <div className="table-container table-padding">
              <Row>
                <Col md={6}>
                  <div className="membership-plan">Upcoming Renewal in next 90 Days </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex mt-3 mt-l-0 justify-content-start justify-content-md-end mt-3 mb-2 mt-md-0 mb-md-0">
                    <div>
                      <NavLink to="/EzPlan/renewal-tracking/allrenewal-tracking">
                      <Button className="view-membership-btn">Track All Renewals</Button>
                      </NavLink>
                    </div>
                  </div>
                </Col>
              </Row>
              <div style={{ height: 650, width: "100%" }}>
                <DataGrid
                  className="datagrid-table"
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[7]}
                  checkboxSelection
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RenewalTracking;
