import { React, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Assets } from "../constants/images";
import { Dropdown, DropdownButton } from "react-bootstrap";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
  MenuDots,
  ThreeDots,
  GreenTick,
  Telephone,
  Envelope,
  Loc,
  UserIcon,
} from "../constants/svg";


const CorporateClientCards = ({patientdata}) => {
  return (
    <>
    {/* <div className="patient-card-bg">
    <div className="patient-card-container">
      <div className="d-flex justify-content-between">
        <div>
          <img className="img-fluid" src={Assets.PatientImg} />
          <span className="patient-title">Peter Paul</span>
        </div>
        <div className="user-button">
          <DropdownButton
            id="dropdown-basic-button"
            title={<UserIcon/>}
          >
            <Dropdown.Item
            href="#/action-1">Edit</Dropdown.Item>
            <Dropdown.Item 
            href="#/action-2">
              Delete
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      <div className="d-flex justify-content-between mt_15">
        <div className="patient-data">Amount Recieved: </div>
        <div className="patient-data-info">$200</div>
      </div>
      <div className="d-flex justify-content-between mt_10">
        <div className="patient-data">Payment Remaining: </div>
        <div className="patient-data-info">$200</div>
      </div>
      <div className="d-flex justify-content-between mt_10">
        <div className="patient-data">Treatments: </div>
        <div className="patient-data-info">22</div>
      </div>
      <div className="d-flex justify-content-between mt_10">
        <div className="patient-data">Appointments: </div>
        <div className="patient-data-info">22</div>
      </div>
      <hr></hr>
      <Row>
        <Col md={6}>
          <span>
            <Telephone />
          </span>
          <span className="icon-text"> +912656348455</span>
        </Col>
        <Col md={6}>
          <span>
            <Envelope />
          </span>
          <span className="icon-text"> test@gmail.com</span>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mt-2">
          <span>
            <Loc />
          </span>
          <span className="icon-text">abc uigdhye </span>
        </Col>
      </Row>
    </div>
  </div>   */}

  {patientdata.map((currElem, index) => {
        return (
          <Col xl={6} lg={6} xxl={4} md={6}>
    <div className="patient-card-bg">
    <div className="patient-card-container">
      <div className="d-flex justify-content-between">
        <div>
          <img className="img-fluid" src={Assets.PatientImg} />
          <span className="patient-title">Peter Paul</span>
        </div>
        <div className="user-button">
          <DropdownButton
            id="dropdown-basic-button"
            title={<UserIcon/>}
          >
            <Dropdown.Item
            href="#/action-1">Edit</Dropdown.Item>
            <Dropdown.Item 
            href="#/action-2">
              Delete
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      <div className="d-flex justify-content-between mt_15">
        <div className="patient-data">Amount Recieved: </div>
        <div className="patient-data-info">$200</div>
      </div>
      <div className="d-flex justify-content-between mt_10">
        <div className="patient-data">Payment Remaining: </div>
        <div className="patient-data-info">$200</div>
      </div>
      <div className="d-flex justify-content-between mt_10">
        <div className="patient-data">Treatments: </div>
        <div className="patient-data-info">22</div>
      </div>
      <div className="d-flex justify-content-between mt_10">
        <div className="patient-data">Appointments: </div>
        <div className="patient-data-info">22</div>
      </div>
      <hr></hr>
      <Row>
        <Col md={6}>
          <span>
            <Telephone />
          </span>
          <span className="icon-text"> +912656348455</span>
        </Col>
        <Col md={6}>
          <span>
            <Envelope />
          </span>
          <span className="icon-text"> test@gmail.com</span>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mt-2">
          <span>
            <Loc />
          </span>
          <span className="icon-text">abc uigdhye </span>
        </Col>
      </Row>
    </div>
  </div>
          </Col>
        );
      })}
  </>
  )
}

export default CorporateClientCards