import { Assets } from "../../../constants/images"
const PatientCardData=[
    {
        id:1,
        patientimage:"{Assets.PatientImg}",
        patientname:"Peter Paul",
        patientdata1: "Amount Recieved:",
        patientdata1ans: "$200",
        patientdata2: "Payment Remaining:",
        patientdata2ans: "$200",
        patientdata3: "Treatments:",
        patientdata3ans: "22",
        patientdata4: "Appointments:",
        patientdata4ans: "22",
        patientcontact: " +912656348455",
        patientemail: "test@gmail.com",
        patientaddress: "abc uigdhye"

    },
    {
        id:2,
        patientimage:"{Assets.PatientImg}",
        patientname:"Peter Paul",
        patientdata1: "Amount Recieved:",
        patientdata1ans: "$200",
        patientdata2: "Payment Remaining:",
        patientdata2ans: "$200",
        patientdata3: "Treatments:",
        patientdata3ans: "22",
        patientdata4: "Appointments:",
        patientdata4ans: "22",
        patientcontact: " +912656348455",
        patientemail: "test@gmail.com",
        patientaddress: "abc uigdhye"

    },
    {
        id:3,
        patientimage:"{Assets.PatientImg}",
        patientname:"Peter Paul",
        patientdata1: "Amount Recieved:",
        patientdata1ans: "$200",
        patientdata2: "Payment Remaining:",
        patientdata2ans: "$200",
        patientdata3: "Treatments:",
        patientdata3ans: "22",
        patientdata4: "Appointments:",
        patientdata4ans: "22",
        patientcontact: " +912656348455",
        patientemail: "test@gmail.com",
        patientaddress: "abc uigdhye"

    },
    {
        id:4,
        patientimage:"{Assets.PatientImg}",
        patientname:"Peter Paul",
        patientdata1: "Amount Recieved:",
        patientdata1ans: "$200",
        patientdata2: "Payment Remaining:",
        patientdata2ans: "$200",
        patientdata3: "Treatments:",
        patientdata3ans: "22",
        patientdata4: "Appointments:",
        patientdata4ans: "22",
        patientcontact: " +912656348455",
        patientemail: "test@gmail.com",
        patientaddress: "abc uigdhye"

    },
    {
        id:5,
        patientimage:"{Assets.PatientImg}",
        patientname:"Peter Paul",
        patientdata1: "Amount Recieved:",
        patientdata1ans: "$200",
        patientdata2: "Payment Remaining:",
        patientdata2ans: "$200",
        patientdata3: "Treatments:",
        patientdata3ans: "22",
        patientdata4: "Appointments:",
        patientdata4ans: "22",
        patientcontact: " +912656348455",
        patientemail: "test@gmail.com",
        patientaddress: "abc uigdhye"

    },
    {
        id:6,
        patientimage:"{Assets.PatientImg}",
        patientname:"Peter Paul",
        patientdata1: "Amount Recieved:",
        patientdata1ans: "$200",
        patientdata2: "Payment Remaining:",
        patientdata2ans: "$200",
        patientdata3: "Treatments:",
        patientdata3ans: "22",
        patientdata4: "Appointments:",
        patientdata4ans: "22",
        patientcontact: " +912656348455",
        patientemail: "test@gmail.com",
        patientaddress: "abc uigdhye"

    },
]

export default PatientCardData;