import { React, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Assets } from "../../constants/images";
import Form from "react-bootstrap/Form";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
// import {FaEye, FaEyeSlash} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SignIn = (data) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const history = useHistory();

  const tostmessages = {
    success: {
      message: "Success",
    },
  };
  const [pass, TogglePass] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    const { email, formBasicPassword0 } = data;
    if (
      data.email === "ezfi@tekrevol.com" &&
      data.formBasicPassword0 === "12345678"
    ) {
      toast.success("Successfully Logedin !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        history.push("/dashboard");
      }, 1500);
    } else {
      toast.error("Invalid Login!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        history.push("/");
      }, 1500);
    }
  };

  const [values, setValues] = useState({
    amount: "",
    password: "",
    showPassword: false,
  });

  const myHelper = {
    email: {
      required: "Email is Required",
      pattern: "Invalid Email",
    },
    fullname: {
      required: "Full name is Required",
      minLength: "Min length is 25",
      maxLength: "Max length is 255",
    },
    password: {
      required: "Password is Required",
      minLength: "Min length is 8",
      maxLength: "Max length is 255",
    },
    confrmpassword: {
      required: "Confrmpassword is Required",
      minLength: "Min length is 8",
      maxLength: "Max length is 255",
    },
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="signinform">
      <ToastContainer />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className="form-labels">
            Email Address <span>*</span>
          </Form.Label>
          <div className="input_name"> 
          <Form.Control
            className="onboarding-placeholders"
            type="email"
            placeholder="Email address"
            id={"formSignInEmail"}
            {...register("email", {
              required: {
                value: "required",
                message: "Email is required",
              },
              maxLength: {
                value: 255,
                message: "Max length is 255",
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            })}
          />
       </div>
          
          {errors.email && (
            <span className={"validation-error pr-5"} role="alert">
              {errors.email.message}
            </span>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword0">
          <Form.Label className="form-labels">
            Password <span>*</span>
          </Form.Label>
          <InputGroup className="">
            <FormControl
              className=""
              placeholder="Enter Password"
              autoComplete={"off"}
              {...register("formBasicPassword0", {
                required: {
                  value: true,
                  message: "Password is required",
                },
                minLength: {
                  value: 8,
                  message: "Min length is 8",
                },
                maxLength: {
                  value: 255,
                  message: "Max length is 255",
                },
              })}
              type={pass ? "password" : "text"}
            />

          </InputGroup>

          {errors.formBasicPassword0 && (
            <span className={"validation-error pr-5"} role="alert">
              {errors.formBasicPassword0.message}
            </span>
          )}
        </Form.Group>
        <NavLink className="Links d-inline-block" to="/forgotpassword">
          <div className="forgot-password">Forgot Password?</div>
        </NavLink>
        <Button className="onboarding-btn mb-3 d-block" type="submit">
          Sign In
        </Button>
      </Form>
    </div>
  );
};

export default SignIn;
