import { React, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Assets } from "../constants/images";
import { Dropdown, DropdownButton } from "react-bootstrap";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
  MenuDots,
  ThreeDots,
  GreenTick,
  Telephone,
  Envelope,
  Loc,
} from "../constants/svg";
import DeleteRecord from "../components/Modals/RoleManagement/DeleteRecord";
import ThemeModal from "../components/ThemeModal";


const PatientCard = ({ patientdata }) => {
  const [showeditpayment, setShowEditPayment] = useState(false);
  const [showdelete, setShowDelete] = useState(false);

  return (
    <>
      {patientdata.map((currElem, index) => {
        return (
          <Col xl={6} lg={6} xxl={4} md={6}>
            <div className="patient-card-bg">
              <div className="patient-card-container">
                <div className="d-flex justify-content-between">
                  <div>
                    <img className="img-fluid" src={Assets.PatientImg} />
                    <span className="patient-title">Peter Paul</span>
                  </div>
                  <div className="three-dots-bg text-center mt-2">
                    <div className="user-button">
                      <DropdownButton
                        id="dropdown-basic-button"
                        title={<ThreeDots />}
                      >
                        <Dropdown.Item onClick={() => setShowEditPayment(true)}>
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setShowDelete(true)}>
                          Deactivate
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt_15">
                  <div className="patient-data">Amount Recieved: </div>
                  <div className="patient-data-info">$200</div>
                </div>
                <div className="d-flex justify-content-between mt_10">
                  <div className="patient-data">Payment Remaining: </div>
                  <div className="patient-data-info">$200</div>
                </div>
                <div className="d-flex justify-content-between mt_10">
                  <div className="patient-data">Treatments: </div>
                  <div className="patient-data-info">22</div>
                </div>
                <div className="d-flex justify-content-between mt_10">
                  <div className="patient-data">Appointments: </div>
                  <div className="patient-data-info">22</div>
                </div>
                <hr></hr>
                <Row>
                  <Col md={6}>
                    <span>
                      <Telephone />
                    </span>
                    <span className="icon-text"> +912656348455</span>
                  </Col>
                  <Col md={6}>
                    <span>
                      <Envelope />
                    </span>
                    <span className="icon-text"> test@gmail.com</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mt-2">
                    <span>
                      <Loc />
                    </span>
                    <span className="icon-text">abc uigdhye </span>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        );
      })}
      {/* Delete Role MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Alert"}
          content={<DeleteRecord />}
          show={showdelete}
          onHide={() => setShowDelete(false)}
        />
      </div>
      {/* END OF Delete Role MODAL */}
    </>
  );
};

export default PatientCard;
