import { React, useState } from "react";
import { Container, Row, Col} from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../components/StackedChart";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  SettingsIcon,
  LogoutProfile,
  DeleteIcon,
  LockIcon,
  ProfileIcon,
  EditIcon,
  TermsIcon,
  FaqIcon,
  PrivacyIcon,
} from "../../constants/svg";
import { Assets } from "../../constants/images";
import LineGraph from "../../components/LineGraph";
import CareGraph from "../../components/CareGraph";
import TotalPlansGraph from "../../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../../components/Statistics";
import DashboardSidebar from "../../components/DashboardSidebar";
import ProfileBox from "../../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import ThemeModal from "../../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../../components/TopBar";
import PrivacyModal from "../Modals/Contents/PrivacyModal";

const PrivacyPolicy = () => {
  const [show, setShow] = useState(false);
  const [showaddnew, setShowAddNew] = useState(false);
  const handleAddNew = () => setShowAddNew(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [showdelete, setShowDelete] = useState(false);

  // edit modal
  const [showeditnew, setShowEditNew] = useState(false);
  const handleEditNew = () => setShowEditNew(false);
  return (
    <div className="content-management">
      <Row>
        <Col xs={8} sm={8} md={8}>
          <div className="profile-title">
            <h2>Privacy Policy</h2>
          </div>
        </Col>
        <Col xs={4} sm={4} md={4}>
          <div
            onClick={() => setShowAddNew(true)}
            className="text-end edit-icon"
          >
            <EditIcon className="edit-icon" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={11}>
          <div className="terms mt_30">
            This is dummy copy. It is not meant to be read. It has been placed
            here solely to demonstrate the look and feel of finished, typeset
            text. Only for show. He who searches for meaning here will be sorely
            disappointed. These words are here to provide the reader with a
            basic impression of how actual text will appear in its final
            presentation. Think of them merely as actors on a paper stage, in a
            performance devoid of content yet rich in form. That being the case,
            there is really no point in your continuing to read them. After all,
            you have many other things you should be doing.
          </div>
          <div className="terms mt_10">
            It breaks up the intimidating blocks of text and makes the page more
            inviting to read, which is again ironic, considering this is dummy
            copy. It is not meant to be read. Someday this space will be
            occupied by real copy that is meant to be read. What other form of
            filler would dare be so politically incorrect? This is dummy copy.
            It is not meant to be read. Good thing.
          </div>
          <div className="terms mt_10">
            This is dummy copy. It is not meant to be read. Accordingly, it is
            difficult to figure out when to end it. If this were real copy, it
            would have ended long ago, because‚Äîas we all know‚Äîno one reads
            body copy, and even fewer read body copy this long. But then, this
            is dummy copy. It is not meant to be read. Period.{" "}
          </div>
          <div className="terms mt_10">
            This is dummy copy. It's Greek to you. Unless, of course, you're
            Greek, in which case, it really makes no sense. Why, you can't even
            read it! It is strictly for mock-ups. You may mock it up as strictly
            as you wish.
          </div>
        </Col>
      </Row>

      {/* ADD USER MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Privacy Policy"}
          content={<PrivacyModal/>}
          // footer={<LogoutModalButtons />}
          show={showaddnew}
          onHide={() => setShowAddNew(false)}
        />
      </div>
      {/* END OF ADD USER MODAL */}
    </div>
  );
};

export default PrivacyPolicy;
