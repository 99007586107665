import {React, useState} from "react";
import { Col } from "react-bootstrap";
import { GreenTick, ThreeDots } from "../constants/svg";
import { Assets } from "../constants/images";
import ThemeModal from "../components/ThemeModal"
import EditPayemntPlan from "./Modals/PaymentPlan/EditPaymentPlan";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteRecord from "../components/Modals/RoleManagement/DeleteRecord";
import EditFinancingPlan from "./Modals/FinancingPlan/EditFinancingPlan";

const FinancingCard = ({ financingdata }) => {
  const [showeditpayment, setShowEditPayment] = useState(false);
  const [showdelete, setShowDelete] = useState(false);
  return (
    <>
      {financingdata.map((currElem, index) => {
        return (
          <Col lg={6} md={6} xl={4}>
            <div className="financing-card-bg mt-3 mt-lg-0">
              <div className="financing-card-container">
                <div className="d-flex justify-content-between">
                  <div>
                    <img className="img-fluid" src={Assets.Star} />
                  </div>
                  <div className="user-button">
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={<ThreeDots />}
                    >
                      <Dropdown.Item
                        onClick={() => setShowEditPayment(true)}
                        
                      >
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setShowDelete(true)}>
                        Deactivate
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
                <h3>Finance Plan</h3>
                <p>
                  lorem ipsum dolor sit amet, lorem ipsum dolor sit amet,
                  consectetur adipisicing elit, sed do eiusmod tempor incididunt
                  ut labore
                </p>
                <hr className="mb_10"></hr>
                <div className="mb_10">
                  <span>
                    <GreenTick />
                  </span>
                  <span className="payment-source">Minimum Down Payment: </span>
                  <span className="third-party">75%</span>
                </div>
                <div className="mb_10">
                  <span>
                    <GreenTick />
                  </span>
                  <span className="payment-source">Interest Rate: </span>
                  <span className="third-party">25%</span>
                </div>
                <div className="mb_10">
                  <span>
                    <GreenTick />
                  </span>
                  <span className="payment-source">
                    Interest Rate Adjustment:{" "}
                  </span>
                  <span className="third-party">Required</span>
                </div>
                <div className="mb_10">
                  <span>
                    <GreenTick />
                  </span>
                  <span className="payment-source">Discount Eligibility: </span>
                  <span className="third-party">10%</span>
                </div>
                <div className="mb_10">
                  <span>
                    <GreenTick />
                  </span>
                  <span className="payment-source">
                    Calculation of Payment Schedule:{" "}
                  </span>
                  <span className="third-party">Adhoc</span>
                </div>
                <div className="mb_10">
                  <span>
                    <GreenTick />
                  </span>
                  <span className="payment-source">Late Payment Fees: </span>
                  <span className="third-party">$3000</span>
                </div>
                <div className="mb_10">
                  <span>
                    <GreenTick />
                  </span>
                  <span className="payment-source">
                    Insurance Claim Processing Fees:{" "}
                  </span>
                  <span className="third-party">65%</span>
                </div>
                <div className="mb_10">
                  <span>
                    <GreenTick />
                  </span>
                  <span className="payment-source">Extended Financing: </span>
                  <span className="third-party">10 Months</span>
                </div>
              </div>
            </div>
          </Col>
        );
      })}

      {/*Edit Finance Plan MODAL */}
      <div className="pages-modals">
        <ThemeModal
        className="ezfi-modal"
          title={"Edit Financing Plan"}
          content={<EditFinancingPlan/>}
          show={showeditpayment}
          onHide={() => setShowEditPayment(false)}
        />
      </div>
      {/* END OF Edit Finance Plan MODAL*/}

      {/* Delete Role MODAL */}
      <div className="pages-modals">
        <ThemeModal
        className="ezfi-modal"
          title={"Alert"}
          content={<DeleteRecord />}
          show={showdelete}
          onHide={() => setShowDelete(false)}
        />
      </div>
      {/* END OF Delete Role MODAL */}
    </>
  );
};

export default FinancingCard;
