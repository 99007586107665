import { React, useState } from "react";
import CardData from "../pages/PaymentPlan/CardData";
import { Col } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteRecord from "../components/Modals/RoleManagement/DeleteRecord";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
  MenuDots,
  ThreeDots,
  GreenTick,
} from "../constants/svg";
import EditPayemntPlan from "./Modals/PaymentPlan/EditPaymentPlan";
import ThemeModal from "../components/ThemeModal";

const PaymentCard = ({ cardmenuData }) => {
  const [showeditpayment, setShowEditPayment] = useState(false);
  const [showdelete, setShowDelete] = useState(false);

  return (
    <>
      {cardmenuData.map((currElem, index) => {
        return (
          <Col xl={6} lg={6} xxl={4} md={6}>
            <div className="payment-card-bg">
              <div className="card-container">
                <div className="d-flex justify-content-between">
                  <h2>{currElem.title}</h2>
                  <div className="user-button">
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={<ThreeDots />}
                    >
                      <Dropdown.Item onClick={() => setShowEditPayment(true)}>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setShowDelete(true)}>
                        Deactivate
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
                <p>{currElem.description}</p>
                <hr className="mb_10"></hr>
                <div className="mb_10">
                  <span>
                    <GreenTick />
                  </span>
                  <span className="payment-source">{currElem.checkone} </span>
                  <span className="third-party">{currElem.checkoneans}</span>
                </div>
                <div className="mb_10">
                  <span>
                    <GreenTick />
                  </span>
                  <span className="payment-source">{currElem.checktwo} </span>
                  <span className="third-party">{currElem.checktwoans}</span>
                </div>
                <div className="mb_10">
                  <span>
                    <GreenTick />
                  </span>
                  <span className="payment-source">{currElem.checkthree} </span>
                  <span className="third-party">{currElem.checkthreeans}</span>
                </div>
                <div className="mb_10">
                  <span>
                    <GreenTick />
                  </span>
                  <span className="payment-source">{currElem.checkfour} </span>
                  <span className="third-party">{currElem.checkfourans}</span>
                </div>
              </div>
            </div>
          </Col>
        );
      })}
      {/*Edit Payment Plan MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Edit Payment Plan"}
          content={<EditPayemntPlan />}
          show={showeditpayment}
          onHide={() => setShowEditPayment(false)}
        />
      </div>
      {/* END OF Edit Payment Plan MODAL*/}

      {/* Delete Role MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Alert"}
          content={<DeleteRecord />}
          show={showdelete}
          onHide={() => setShowDelete(false)}
        />
      </div>
      {/* END OF Delete Role MODAL */}
    </>
  );
};

export default PaymentCard;
