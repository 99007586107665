import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../../components/StackedChart";
import { DataGrid } from "@mui/x-data-grid";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
  MenuDots,
  FilterIcon,
  UserIcon,
  DeleteBin
} from "../../../constants/svg";
import { Assets } from "../../../constants/images";
import LineGraph from "../../../components/LineGraph";
import CareGraph from "../../../components/CareGraph";
import TotalPlansGraph from "../../../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../../../components/Statistics";
import DashboardSidebar from "../../../components/DashboardSidebar";
import ProfileBox from "../../../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../../../components/ResponsiveNavbar";
import ThemeModal from "../../../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../../../components/TopBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FilterBtn from "../../../components/FilterBtn";
import DeleteRecord from "../../../components/Modals/RoleManagement/DeleteRecord";
import EditUser from "../../../components/Modals/UserManagement/EditUser";
import AddUser from "../../../components/Modals/UserManagement/AddUser";
import AddCategoryDiscount from "../../../components/Modals/CategoryDiscount/AddCategoryDiscount";
import EditCategoryDiscount from "../../../components/Modals/CategoryDiscount/EditCategoryDiscount";

const CategoryDiscount = () => {

  const [show, setShow] = useState(false);
  const [showaddnew, setShowAddNew] = useState(false);
  const handleAddNew = () => setShowAddNew(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [showdelete, setShowDelete] = useState(false);
  const [showeditnew, setShowEditNew] = useState(false);
  const handleEditNew = () => setShowEditNew(false);

  const userActions = (data) => {
    return (
      <DropdownButton
        align="end"
        className={"actionDropdown"}
        id="dropdown-item-button"
        title={<MenuDots />}
      >
        <Dropdown.Item as="button">Edit</Dropdown.Item>
        <Dropdown.Item as="button">Delete</Dropdown.Item>
      </DropdownButton>
    );
  };

  // const userActions = (rowData) => {
  //   return (
  //     <DropdownButton
  //       align="end"
  //       className={"actionDropdown"}
  //       id="dropdown-item-button"
  //       title={<MenuDots />}
  //     >
  //       <Dropdown.Item as="button">Edit</Dropdown.Item>
  //       <Dropdown.Item as="button">Delete</Dropdown.Item>
  //     </DropdownButton>
  //   );
  // };

  const columns = [
    {
      field: "UserId",
      headerName: "User ID",
      width: 230,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "fullname",
      headerName: "Full Name",
      width: 230,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email Address",
      width: 230,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "role",
      headerName: "Role",
      width: 230,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: "Action",
      width: 120,
      id: "action",
      name: "Action",
      renderCell: (cellValues) => {
        return (
          <div className="user-button">
          <DropdownButton
            id="dropdown-basic-button"
            title={<UserIcon/>}
          >
            <Dropdown.Item
            onClick={() => setShowEditNew(true)}
            href="#/action-1">Edit</Dropdown.Item>
            <Dropdown.Item 
            onClick={() => setShowDelete(true)}
            href="#/action-2">
              Delete
            </Dropdown.Item>
          </DropdownButton>
        </div>
        );
      },    },
  ];

  const rows = [
    {
      id: 1,
      UserId: "01",
      fullname: "Paul Doe",
      email: "PaulDoe@gmai.com",
      role: "Patient",
      action: userActions(),
    },

    {
      id: 2,
      UserId: "02",
      fullname: "John Doe",
      email: "PaulDoe@gmai.com",
      role: "Patient",
    },

    {
      id: 3,
      UserId: "03",
      fullname: "Ahmed",
      email: "PaulDoe@gmai.com",
      role: "Patient",
    },
    {
      id: 4,
      UserId: "04",
      fullname: "Mikaal",
      email: "PaulDoe@gmai.com",
      role: "Patient",
    },
    {
      id: 5,
      UserId: "05",
      fullname: "Michael Fiz",
      email: "PaulDoe@gmai.com",
      role: "Patient",
    },
    {
      id: 6,
      UserId: "06",
      fullname: "Laura Michel",
      email: "PaulDoe@gmai.com",
      role: "Patient",
    },
    {
      id: 7,
      UserId: "07",
      fullname: "Elijah William",
      email: "PaulDoe@gmai.com",
      role: "Patient",
    },
    {
      id: 8,
      UserId: "08",
      fullname: "Laura Michel",
      email: "PaulDoe@gmai.com",
      role: "Patient",
    },
    {
      id: 9,
      UserId: "09",
      fullname: "Elijah William",
      email: "PaulDoe@gmai.com",
      role: "Patient",
    },
  ];
  return (
    <>
      <div className="user-management">
        <Row>
          <Col md={12}>
            <div className="breadcrumbs">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <NavLink to="/dashboard">Dashboard</NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <NavLink to="/EzPlan/">Ez Plan Dashboard</NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <NavLink to="/EzPlan/CategoryDiscount">Category Discounts</NavLink>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={6}>
            <h2>Category Discounts</h2>
          </Col>
          <Col md={12} lg={6}>
            <div className="d-flex justify-content-start justify-content-lg-end mb-4 mb-lg-0">
              <div 
              onClick={() => setShowDelete(true)}
              className="delete-bg">
                <DeleteBin/>
              </div>
            <div className="user-filter-btn ms-3">
                <DropdownButton
                  id="dropdown-basic-button"
                  className="user-filter-btn"
                  title={<FilterBtn/>}
                >
                  <Dropdown.Item href="#/action-1">Email</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Role</Dropdown.Item>
                </DropdownButton>
              </div>
              <div>
                <Button
                  onClick={() => setShowAddNew(true)}
                  className="add-new-btn ms-3"
                >
                  + Add New
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="table-container">
              <div style={{ height: 650, width: "100%" }}>
                <DataGrid
                  className="datagrid-table"
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[7]}
                  checkboxSelection
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>


            {/* USER MANAGEMENT MODAL STARTS HERE */}

      {/* ADD USER MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Add Category Discount"}
          content={<AddCategoryDiscount/>}
          // footer={<LogoutModalButtons />}
          show={showaddnew}
          onHide={() => setShowAddNew(false)}
        />
      </div>
      {/* END OF ADD USER MODAL */}

      {/* Edit USER MODAL */}
      <ThemeModal
        className="ezfi-modal"
        title={"Edit Category Discount"}
        content={<EditCategoryDiscount/>}
        // footer={<LogoutModalButtons />}
        show={showeditnew}
        onHide={() => setShowEditNew(false)}
      />
      {/* END OF Edit USER MODAL */}

      {/* EDIT USER MODAL */}
      {/* END OF EDIT USER MODAL */}

      {/* Delete User MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Alert"}
          content={<DeleteRecord/>}
          show={showdelete}
          onHide={() => setShowDelete(false)}
        />
      </div>
      {/* END OF Delete User MODAL */}
      {/* USER MANAGEMENT MODAL ENDS HERE */}


    </>
  );
};

export default CategoryDiscount;
