import { React, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Eye, EyeClose, Tick } from "../../../constants/svg";
import { Assets } from "../../../constants/images";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
// import {FaEye, FaEyeSlash} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../../../constants/app-constants";
const LogoutModal = () => {
  return (
    <div>
        <div className='sure'>Are you sure you want to logout?</div>
        <div className="delete-modal-btns text-end">        <NavLink to="/">
          <Button
            className="modal_blue_btn"
            variant="primary"
          >
            Logout
          </Button>
        </NavLink>
        <Button
          className="modal_gray_btn"
          variant="secondary"
        >
          Cancel
        </Button></div>
    </div>
  )
}

export default LogoutModal