import React from 'react';
import { Container, Button, Row, Col } from "react-bootstrap";
import { Assets } from "../constants/images";
import Form from "react-bootstrap/Form";
import { NavLink } from 'react-router-dom';

const AuthLayout = (props) => {
  return (

    <Container>

      <div className="onboarding-container">

        <Row>
            <Col md={12} lg={12} xl={6}>
            <div className="signin-container text-cetner text-lg-none">
    <img src={Assets.Logo} className="img-fluid ezfi-logo" />
    <div className="onboarding-heading">{props.heading}</div>
    <div className="onboarding-text">{props.description}</div>
    {props.children}

  </div>
            </Col>
            <Col md={12} lg={12} xl={6} className='text-end d-none d-xl-block'>
            <img src={Assets.OnboardingImg} className="img-fluid woman-img" />
            </Col>
        </Row>

        </div>
    </Container>

  );
};




export default AuthLayout