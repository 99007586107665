import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../components/StackedChart";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  SettingsIcon,
  LogoutProfile,
  DeleteIcon,
  LockIcon,
  ProfileIcon,
  EditIcon,
  TermsIcon,
  FaqIcon,
  PrivacyIcon,
  FAQs
} from "../../constants/svg";
import { Assets } from "../../constants/images";
import LineGraph from "../../components/LineGraph";
import CareGraph from "../../components/CareGraph";
import TotalPlansGraph from "../../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../../components/Statistics";
import DashboardSidebar from "../../components/DashboardSidebar";
import ProfileBox from "../../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import ThemeModal from "../../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../../components/TopBar";
const ContentManagement = (props) => {
  return (
        <>
          <section className="settings-body">
            <Row>
              <Col xl={3} lg={3}>
                <div className="settings-bar">
                  <div className="settings-navigation">
                    <ul>
                      <li className="setting-active">
                        <NavLink to="/content/termsandcondition">
                        <div className="content-nav-bg">
                          <span className="settings-icon">
                            <TermsIcon />
                          </span>
                          <span>Terms and Conditions</span>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/content/privacypolicy">
                        <div className="content-nav-bg">
                          <span className="settings-icon">
                            <PrivacyIcon />
                          </span>
                          <span>Privacy Policy</span>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/content/faq">
                        <div className="content-nav-bg">
                          <span className="settings-icon">
                            <FAQs/>
                          </span>
                          <span>FAQs</span>
                          </div>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col xl={9} lg={9}>
                <div className="settings-bar">{props.children}</div>
              </Col>
            </Row>
          </section>
        </>
  );
}

export default ContentManagement