import React from 'react';
import  { Profilearrow
} from "../constants/svg";
import { Assets } from "../constants/images";

const ProfileBox = () => {
  return (
    <div className="profile-box text-start d-flex">             
    <img
    src={Assets.ProfileAvatar}
    className="profile-img"
  />
  <div className="profile-name">Aaron Martin</div>
  <div className="mt-2 ms-3 mx-3"><Profilearrow/></div>
  </div>
  )
}

export default ProfileBox