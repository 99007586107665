import { React, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import DateRange from "./DateRange";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {DateRangeCalender} from '../constants/svg'

const EzPayRevenueGraph = () => {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const range = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last Year": [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year"),
    ],
  };

  const handleEvent = (event, picker) => {
    setFromDate(picker.startDate._d.toISOString());
    setToDate(picker.endDate._d.toISOString());
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  const labels = ["01", "02", "03", "04", "05", "06", "07"];

  const data = {
    labels,
    datasets: [
      {
        label: "Active",
        data: labels.map(() =>
          faker.datatype.number({ min: -1000, max: 1000 })
        ),
        borderColor: "rgb(59,122,254)",
        backgroundColor: "rgba(59,122,254 0.5)",
        lineTension: 0.3,
      },
      {
        label: "In Active",
        data: labels.map(() =>
          faker.datatype.number({ min: -1000, max: 1000 })
        ),
        borderColor: "rgb(95,191,36)",
        backgroundColor: "rgba(95,191,36 0.5)",
        lineTension: 0.3,
      },
    ],
  };

  const data2 = {
    labels: ["01", "02", "03", "04", "05", "06"],
    datasets: [
      {
        label: "Active",
        data: [0, 15, 25, 35, 30, 40],
        fill: true,
        backgroundColor: "rgba(59,122,254,0.2)",
        borderColor: "rgba(59,122,254,1)",
        lineTension: 0.3,
      },
      {
        label: "In Active",
        data: [0, 10, 20, 30, 25, 35],
        fill: true,
        borderColor: "rgba(95,191,36,1)",
        lineTension: 0.3,
      },
    ],
  };

  return (
    <div className="LineGraph">
      <Line options={options} data={data2} />
    </div>
  );
};

export default EzPayRevenueGraph;
