import { React, useState } from "react";
import { Container, Button, Row, Col, FormControl } from "react-bootstrap";
import { Assets } from "../../constants/images";
import Form from "react-bootstrap/Form";
import { Cross } from "../../constants/svg";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
// import {FaEye, FaEyeSlash} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const CreateNewPass = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const history = useHistory();

  const tostmessages = {
    success: {
      message: "Success",
    },
  };
  const [pass, TogglePass] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const password = watch("formBasicPassword");

  const onSubmit = async (data) => {
    const { email, formBasicPassword } = data;
    if (
      data.email === "ezfi@tekrevol.com" &&
      data.formBasicPassword === "12345678"
    ) {
      toast.success("Successfully Logedin !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        history.push("/dashboard");
      }, 1500);
    } else {
      toast.error("Invalid Login!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        history.push("/");
      }, 1500);
    }
  };

  const [values, setValues] = useState({
    amount: "",
    password: "",
    showPassword: false,
  });

  const myHelper = {
    email: {
      required: "Email is Required",
      pattern: "Invalid Email",
    },
    fullname: {
      required: "Full name is Required",
      minLength: "Min length is 25",
      maxLength: "Max length is 255",
    },
    password: {
      required: "Password is Required",
      minLength: "Min length is 8",
      maxLength: "Max length is 255",
    },
    confrmpassword: {
      required: "Confrmpassword is Required",
      minLength: "Min length is 8",
      maxLength: "Max length is 255",
    },
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="signinform">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label className="form-labels">
            New Password <span>*</span>
          </Form.Label>
          <inputGroup>
          <FormControl
            className="onboarding-placeholders"
            placeholder="Enter Password"
            autoComplete={"off"}
            {...register("formBasicPassword", {
              required: {
                value: true,
                message: "Password is required",
              },
              minLength: {
                value: 8,
                message: "Min length is 8",
              },
              maxLength: {
                value: 255,
                message: "Max length is 255",
              },
            })}
            type={pass ? "password" : "text"}
          />
          </inputGroup>

          {errors.formBasicPassword && (
            <span className={"validation-error pr-5"} role="alert">
              {errors.formBasicPassword.message}
            </span>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword2">
          <Form.Label className="form-labels">
            Confirm Password <span>*</span>
          </Form.Label>
          <FormControl
            className="onboarding-placeholders"
            placeholder="Enter Password"
            autoComplete={"off"}
            {...register("formBasicPassword2", {
              required: {
                value: true,
                message: "Password is required",
              },
              minLength: {
                value: 8,
                message: "Min length is 8",
              },
              maxLength: {
                value: 255,
                message: "Max length is 255",
              },
              validate: (value) => value === password || "Password Not Matched",
            })}
            type={pass ? "password" : "text"}
          />

          {errors.formBasicPassword2 && (
            <span className={"validation-error pr-5"} role="alert">
              {errors.formBasicPassword2.message}
            </span>
          )}
        </Form.Group>
        <Button className="onboarding-btn mb-3 mt-4" type="submit">
          Update Password
        </Button>
      </Form>
    </div>
  );
};

export default CreateNewPass;
