import { React, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import {Eye,EyeClose, Tick} from '../../../constants/svg'
import { Assets } from "../../../constants/images";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
// import {FaEye, FaEyeSlash} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
const Signin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const history = useHistory();
  const [confirmPass, ToggleConfirmPass] = useState(true);

  const tostmessages = {
    success: {
      message: "Success",
    },
  };
  const [pass, TogglePass] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    const { email, formBasicPassword0 } = data;
    if (
      data.email === "ezfi@tekrevol.com" &&
      data.formBasicPassword0 === "12345678"
    ) {
      toast.success("Successfully Logedin !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        history.push("/dashboard");
      }, 1500);
    } else {
      toast.error("Invalid Login!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        history.push("/");
      }, 1500);
    }
  };

  const [values, setValues] = useState({
    amount: "",
    password: "",
    showPassword: false,
  });

  const myHelper = {
    email: {
      required: "Email is Required",
      pattern: "Invalid Email",
    },
    fullname: {
      required: "Full name is Required",
      minLength: "Min length is 25",
      maxLength: "Max length is 255",
    },
    password: {
      required: "Password is Required",
      minLength: "Min length is 8",
      maxLength: "Max length is 255",
    },
    confrmpassword: {
      required: "Confrmpassword is Required",
      minLength: "Min length is 8",
      maxLength: "Max length is 255",
    },
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="formBasicEmail" className="input_main">
        <Form.Label>
          Email Address <span className="form_star">*</span>
        </Form.Label>
        <InputGroup>
        <Form.Control
          className="onboarding-placeholders"
          type="email"
          placeholder="Email address"
          id={"formSignInEmail"}
          {...register("email", {
            required: {
              value: "required",
              message: "Email is required",
            },
            maxLength: {
              value: 255,
              message: "Max length is 255",
            },
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Entered value does not match email format",
            },
          })}
        />
                <InputGroup.Text id="basic-addon2"><Tick/></InputGroup.Text>
                </InputGroup>

        {errors.email && (
          <span className={"validation-error pr-5"} role="alert">
            {errors.email.message}
          </span>
        )}
      </Form.Group>

      <Form.Group controlId="formBasicPassword" className="input_main">
        <Form.Label>
          Password <span className="form_star">*</span>
        </Form.Label>
        <InputGroup>
          <FormControl
            className=""
            placeholder="Enter Password"
            autoComplete={"off"}
            {...register("formBasicPassword0", {
              required: {
                value: true,
                message: "Password is required",
              },
              minLength: {
                value: 8,
                message: "Min length is 8",
              },
              maxLength: {
                value: 255,
                message: "Max length is 255",
              },
            })}
            type={pass ? "password" : "text"}
          />
        <InputGroup.Text id="basic-addon2" onClick={() => TogglePass(!pass)}>
                                {
                                    pass ? <Eye/> : <EyeClose/>
                                }
                            </InputGroup.Text>
        </InputGroup>

        {errors.formBasicPassword0 && (
          <span className={"validation-error pr-5"} role="alert">
            {errors.formBasicPassword0.message}
          </span>
        )}
      </Form.Group>




      <div className="forgot_pass">
        {" "}
        <Link to="/forgotpassword">Forgot Password?</Link>
      </div>

      <div className="main_btn">
        <Button className="main_form_btn" type="submit">
          Sign In
        </Button>
      </div>
    </Form>
  );
};

export default Signin;
