import {React, useState} from "react";
import Accordion from "react-bootstrap/Accordion";
import { Col, Row } from "react-bootstrap";
import { ThreeDots } from "../constants/svg";
import { Dropdown, DropdownButton } from "react-bootstrap";
import DeleteRecord from "./Modals/RoleManagement/DeleteRecord";
import ThemeModal from "./ThemeModal";
import AddFaq from "./Modals/FAQ/AddFaq";

const FaqAccordion = () => {
  const [show, setShow] = useState(false);
  const [showaddnew, setShowAddNew] = useState(false);
  const handleAddNew = () => setShowAddNew(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [showdelete, setShowDelete] = useState(false);

  // edit modal
  const [showeditnew, setShowEditNew] = useState(false);
  const handleEditNew = () => setShowEditNew(false);

  return (
    <div className="faq-accordion">
      <Accordion defaultActiveKey="0">
        <Row>
          <Col xs={10} sm={11}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Frequently Asked Question 1</Accordion.Header>
              <Accordion.Body>
                This is dummy copy. It is not meant to be read. It has been
                placed here solely to demonstrate the look and feel of finished,
                typeset text. Only for show. He who searches for meaning here
                will be sorely disappointed.
              </Accordion.Body>
            </Accordion.Item>
          </Col>
          <Col xs={2} sm={1}>
            <div className="user-button mt-3">
              <DropdownButton id="dropdown-basic-button" title={<ThreeDots />}>
                <Dropdown.Item onClick={() => setShowEditNew(true)}>Edit</Dropdown.Item>
                <Dropdown.Item onClick={() => setShowDelete(true)}>Deactivate</Dropdown.Item>
              </DropdownButton>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={10} sm={11}>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Frequently Asked Question 2</Accordion.Header>
              <Accordion.Body>
                This is dummy copy. It is not meant to be read. It has been
                placed here solely to demonstrate the look and feel of finished,
                typeset text. Only for show. He who searches for meaning here
                will be sorely disappointed.
              </Accordion.Body>
            </Accordion.Item>
          </Col>
          <Col xs={2} sm={1}>
            <div className="user-button mt-3">
              <DropdownButton id="dropdown-basic-button" title={<ThreeDots />}>
              <Dropdown.Item onClick={() => setShowEditNew(true)}>Edit</Dropdown.Item>
                <Dropdown.Item onClick={() => setShowDelete(true)}>Deactivate</Dropdown.Item>
              </DropdownButton>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={10} sm={11}>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Frequently Asked Question 3</Accordion.Header>
              <Accordion.Body>
                This is dummy copy. It is not meant to be read. It has been
                placed here solely to demonstrate the look and feel of finished,
                typeset text. Only for show. He who searches for meaning here
                will be sorely disappointed.
              </Accordion.Body>
            </Accordion.Item>
          </Col>
          <Col xs={2} sm={1}>
            <div className="user-button mt-3">
              <DropdownButton id="dropdown-basic-button" title={<ThreeDots />}>
              <Dropdown.Item onClick={() => setShowEditNew(true)}>Edit</Dropdown.Item>
                <Dropdown.Item onClick={() => setShowDelete(true)}>Deactivate</Dropdown.Item>
              </DropdownButton>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={10} sm={11}>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Frequently Asked Question 4</Accordion.Header>
              <Accordion.Body>
                This is dummy copy. It is not meant to be read. It has been
                placed here solely to demonstrate the look and feel of finished,
                typeset text. Only for show. He who searches for meaning here
                will be sorely disappointed.
              </Accordion.Body>
            </Accordion.Item>
          </Col>
          <Col xs={2} sm={1}>
            <div className="user-button mt-3">
              <DropdownButton id="dropdown-basic-button" title={<ThreeDots />}>
                <Dropdown.Item>Edit</Dropdown.Item>
                <Dropdown.Item>Deactivate</Dropdown.Item>
              </DropdownButton>
            </div>
          </Col>
        </Row>
      </Accordion>

            {/* Delete User MODAL */}
            <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Alert"}
          content={<DeleteRecord/>}
          show={showdelete}
          onHide={() => setShowDelete(false)}
        />
      </div>
      {/* END OF Delete User MODAL */}

                  {/* ADD USER MODAL */}
                  <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Edit Faq"}
          content={<AddFaq/>}
          // footer={<LogoutModalButtons />}
          show={showeditnew}
          onHide={() => setShowEditNew(false)}
        />
      </div>
      {/* END OF ADD USER MODAL */}
    </div>
  );
};

export default FaqAccordion;
