const FinancingCardData=[{
    id:1,
    title:"Finance Plan 1"
},
{
    id:2,
    title:"Finance Plan 2"
},
{
    id:3,
    title:"Finance Plan 3"
}
]

export default FinancingCardData