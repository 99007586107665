import { React, useState } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { Assets } from "../../../constants/images";
import Form from "react-bootstrap/Form";
import OtpInput from "react-otp-input";
import { NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router";
const Accountotp = () => {
    const [value, setValue] = useState("");
    const [isInvalid, setIsInvalid] = useState(false);
    const [error, setError] = useState(null);
    const [otp, setOtp] = useState();
    const [seconds, setSeconds] = useState(null);
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState("");
  
    const {
      register,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm({
      mode: "onChange",
    });
  
    const handleChange = (value) => {
      setOtp(value);
    };
  
    const onSubmit = (data) => {
      if (otp.length === 0) {
        setError(true);
        setErrorMessage("Please Enter OTP code");
      } else if (otp.length > 0 && otp.length < 5) {
        setError(true);
        setErrorMessage("OTP code is incomplete");
      } else {
        setError(false);
        toast.success("OTP verified");
        setTimeout(() => {
          history.push("/passwordconfirm");
        }, 1500);
      }
    };
    const handleOnSubmit = (data) => {
      if (otp.length === 0) {
        toast.error("Invalid OTP!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          history.push("/verify-code");
        }, 1500);
      } else if (otp.length > 0 && otp.length < 5) {
        toast.error("Invalid OTP!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          history.push("/account-verification");
        }, 1500);
      } else {
        toast.success("Success Verify !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          history.push("/create-new-pass");
        }, 1500);
      }
    };
  
    const myHelper = {
      message: {
        required: "Invalid",
        minLength: "Please Enter complete 6 digits code",
      },
    };
    const handleOtpChange = (otp) => {
      setOtp(otp);
      if (otp.length === 0) {
        setError(true);
        setErrorMessage("Please Enter OTP code");
      } else if (otp.length > 0 && otp.length < 5) {
        setError(true);
        setErrorMessage("OTP code is incomplete");
      } else {
        setError(false);
      }
    };
    const [showPassword, setShowPassword] = useState(false);
  
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
    <div className="otp">
      <OtpInput
        className="otp-input"
        value={otp}
        onChange={(otp) => handleOtpChange(otp)}
        numInputs={5}
        isInputNum={true}
        placeholder="-----"
        hasErrored={true}
        focusStyle="focus"
      />
      {error && (
        <small className="validation-error text-red ms-1">{errorMessage}</small>
      )}
      
    </div>
    <div className='resend-code'>
      Resend the code
    </div>
    <div className="main_btn">
      <Button className="main_form_btn" type="submit">
        Verify Now
      </Button>
    </div>
  </Form>
  )
}

export default Accountotp