import React from "react";
import {
  BrowserRouter,
  Routes,
  Switch,
  Route,
  Link,
  Router,
} from "react-router-dom";
import SignIn from "../auth/components/SignIn";
import Login from "../auth/components/Login";
import ForgotPassword from "../auth/components/ForgotPassword";
import CreateNewPass from "../auth/components/CreateNewPass";
import AccountVerification from "../auth/components/AccountVerification";
import Dashboard from "../pages/Dashboard";
import AuthLayout from "../../src/auth/AuthLayout";
import NewComponent from "../auth/components/NewComponent";
import Signin from "../auth/components/NewComponents/Signin";
import ForgotPass from "../auth/components/NewComponents/ForgotPass";
import Accountotp from "../auth/components/NewComponents/Accountotp";
import Passwordconfirm from "../auth/components/NewComponents/Passwordconfirm";
import Settings from "../pages/Settings/Settings";
import MyProfile from "../components/Settings/MyProfile";
import ChangePassword from "../components/Settings/ChangePassword";
import DeleteAccount from "../components/Settings/DeleteAccount";
import DashboardLayout from "../pages/DashboardLayout";
import UserManagement from "../pages/UserManagement/UserManagement";
import AppointmentPage from "../pages/Appointment/AppointmentPage";
import RoleManagement from "../pages/RoleManagement/RoleManagement";
import PaymentPlan from "../pages/PaymentPlan/PaymentPlan";
import Patients from "../pages/EZPlan/Patients/Patients.jsx";
import CorporateClients from "../pages/EZPlan/CorporateClients/CorporateClients.jsx";
import MarketingPage from "../pages/Marketing/MarketingPage";
import FinancingPage from "../pages/Financing/FinancingPage";
import Administration from "../pages/Administration/Administration.jsx";
import Content from "../pages/ContentManagement/Content";
import TermsandCondition from "../components/contents/TermsandCondition";
import ContentManagement from "../pages/ContentManagement/Content";
import PrivacyPolicy from "../components/contents/PrivacyPolicy";
import Faq from "../components/contents/Faq";
import EzPayRecords from "../pages/EzPay/EzPayRecords";
import MembershipPlan from "../pages/EZPlan/MembershipPlan/MembershipPlan";
import FeaturedPlans from "../pages/EZPlan/MembershipPlan/FeaturedPlans";
import RenewalTracking from "../pages/EZPlan/Renewal Tracking/RenewalTracking";
import EzPlanDashboard from "../pages/EZPlan/EzPlanDashboard/EzPlanDashboard";
import EzPayDashboard from "../pages/EzPay/EzPayDashboard";
import ProposalProfile from "../pages/Marketing/ProposalProfile";
import FeeSchedule from "../pages/EZPlan/FeeSchedule/FeeSchedule";
import CategoryDiscount from "../pages/EZPlan/CategoryDiscount/CategoryDiscount";
import AllRenewalTracking from "../pages/EZPlan/Renewal Tracking/AllRenewalTracking";
import ViewAllMembership from "../pages/EZPlan/MembershipPlan/ViewAllMembership";

const Navigation = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* New Components */}
        <Route exact path="/">
          <NewComponent
            heading="Sign in"
            description="Let's get you in to the app"
          >
            <Signin />
          </NewComponent>
        </Route>
        <Route exact path="/forgotpassword">
          <NewComponent
            heading="Forgot Password"
            description="Enter email address to receive a 4 digit code for verification"
          >
            <ForgotPass />
          </NewComponent>
        </Route>
        <Route exact path="/verifyaccount">
          <NewComponent
            heading="Account Verification"
            description="Please enter the OTP you received to
                sam.marsh@gmail.com"
          >
            <Accountotp />
          </NewComponent>
        </Route>
        <Route exact path='/passwordconfirm'>
          <NewComponent heading='Create New Password' description='Your new password must be different from
the previously used passwords.'>
            <Passwordconfirm/>
          </NewComponent>
        </Route>
        {/* New Components End */}
        <Route exact path="/abc">
          <AuthLayout
            heading="Sign In"
            description="Let’s get you in to the app"
          >
            <SignIn />
          </AuthLayout>
        </Route>
        <Route exact path="/Login">
          <AuthLayout
            heading="Sign In"
            description="Let’s get you in to the app"
          >
            <SignIn />
          </AuthLayout>
        </Route>
        <Route exact path="/Forgot-password">
          <AuthLayout
            heading="Forgot Password"
            description="Enter email address to recieve a 4 digit code for verification"
          >
            <ForgotPassword />
          </AuthLayout>
        </Route>
        <Route exact path="/create-new-password">
          <AuthLayout
            heading="Create New Password"
            description="Your new password must be different from previously used passwords."
          >
            <CreateNewPass />
          </AuthLayout>
        </Route>
        <Route exact path="/account-verification">
          <AuthLayout
            heading="Account Verification"
            description="Please enter the OTP you received to sam.marsh@gmail.com"
          >
            <AccountVerification />
          </AuthLayout>
        </Route>
        <Route exact path="/dashboard">
          <DashboardLayout pagetitle="Dashboard">
          <Dashboard />
          </DashboardLayout>
        </Route>
        <Route exact path="/settings/myprofile">
          <DashboardLayout pagetitle="Settings">
          <Settings>
            <MyProfile/>
          </Settings>
          </DashboardLayout>
        </Route>
        <Route exact path="/settings/changepassword">
          <DashboardLayout pagetitle="Settings">
          <Settings>
            <ChangePassword/>
          </Settings>
          </DashboardLayout>
        </Route>
        <Route exact path="/settings/deleteaccount">
          <DashboardLayout pagetitle="Settings">
          <Settings>
            <DeleteAccount/>
          </Settings>
          </DashboardLayout>
        </Route>
        <Route exact path="/usermanagement">
          <DashboardLayout>
            <UserManagement/>
          </DashboardLayout>
        </Route>
        <Route exact path="/appointment">
          <DashboardLayout>
            <AppointmentPage/>
          </DashboardLayout>
        </Route>
        <Route exact path="/rolemanagement">
          <DashboardLayout>
            <RoleManagement/>
          </DashboardLayout>
        </Route>
        <Route exact path="/paymentplan">
          <DashboardLayout>
            <PaymentPlan/>
          </DashboardLayout>
        </Route>
        <Route exact path="/EzPlan/patients">
          <DashboardLayout>
            <Patients/>
          </DashboardLayout>
        </Route>
        <Route exact path="/EzPlan/CorporateClients">
          <DashboardLayout>
            <CorporateClients/>
          </DashboardLayout>
        </Route>
        <Route exact path="/Marketing">
          <DashboardLayout>
            <MarketingPage/>
          </DashboardLayout>
        </Route>
        <Route exact path="/Financing">
          <DashboardLayout>
            <FinancingPage/>
          </DashboardLayout>
        </Route>
        <Route exact path="/administration">
          <DashboardLayout>
            <Administration/>
          </DashboardLayout>
        </Route>
        <Route exact path="/content/termsandcondition">
          <DashboardLayout pagetitle="Content Management">
          <Content>
            <TermsandCondition/>
          </Content>
          </DashboardLayout>
        </Route>
        <Route exact path="/content/privacypolicy">
          <DashboardLayout pagetitle="Content Management">
            <Content>
              <PrivacyPolicy/>
            </Content>
          </DashboardLayout>
        </Route>
        <Route exact path="/content/faq">
          <DashboardLayout pagetitle="Content Management">
            <Content>
              <Faq/>
            </Content>
          </DashboardLayout>
        </Route>
        <Route exact path="/EzPay/Dashboard/Records">
          <DashboardLayout>
            <EzPayRecords/>
          </DashboardLayout>
        </Route>
        <Route exact path="/EzPlan/membership-plan">
          <DashboardLayout>
            <MembershipPlan/>
          </DashboardLayout>
        </Route>
        <Route exact path="/EzPlan/featured-plans">
        <DashboardLayout>
          <FeaturedPlans/>
        </DashboardLayout>
        </Route>
        <Route exact path="/EzPlan/renewal-tracking">
          <DashboardLayout>
            <RenewalTracking/>
          </DashboardLayout>
        </Route>
        <Route exact path="/EzPlan/view-all-membership-plan">
          <DashboardLayout>
            <ViewAllMembership/>
          </DashboardLayout>
        </Route>
        <Route exact path='/EzPlan'>
          <DashboardLayout>
            <EzPlanDashboard/>
          </DashboardLayout>
        </Route>
        <Route exact path="/EzPay/Dashboard">
          <DashboardLayout>
            <EzPayDashboard/>
          </DashboardLayout>
        </Route>
        <Route exact path="/Marketing/ProposalProfile">
          <DashboardLayout>
            <ProposalProfile/>
          </DashboardLayout>
        </Route>
        <Route exact path="/EzPlan/FeeSchedule">
          <DashboardLayout>
            <FeeSchedule/>
          </DashboardLayout>
        </Route>
        <Route exact path="/EzPlan/CategoryDiscount">
          <DashboardLayout>
            <CategoryDiscount/>
          </DashboardLayout>
        </Route>
        <Route exact path="/EzPlan/renewal-tracking/allrenewal-tracking">
          <DashboardLayout>
            <AllRenewalTracking/>
          </DashboardLayout>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Navigation;
