import { React, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Eye, EyeClose, Tick } from "../../../constants/svg";
import { Assets } from "../../../constants/images";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router";
// import {FaEye, FaEyeSlash} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
    VALIDATIONS,
    VALIDATIONS_TEXT,
  } from "../../../constants/app-constants";


const AddCategoryDiscount = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = data => console.log(data);
  return (
    <div className="user-modal validation-margin">
      <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="formBasicEmail">
          <Form.Label>Category</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Category"
            {...register("payment", {
              maxLength: {
                value: VALIDATIONS.ROLE_NAME,
                message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
              },
              required: {
                value: true,
                message: "Category is requried",
              },
            })}
          />
          {errors.payment && <span className="validations">{errors.payment.message}</span>}
        </Form.Group>

        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            {...register("description", {
              maxLength: {
                value: VALIDATIONS.ROLE_NAME,
                message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
              },
              required: {
                value: true,
                message: "Description is requried",
              },
            })}
          />
          {errors.description && <span  className="validations">{errors.description.message}</span>}
        </Form.Group>

        <Form.Group>
          <Form.Label>Percent Discount</Form.Label>
            <Form.Select aria-label="Default select example">
              <option>10%</option>
              <option value="1">20%</option>
              <option value="2">30%</option>
              <option value="3">40$</option>
            </Form.Select>
        </Form.Group>
        <div className="text-end">
        <Button className="modal_submit_btn" type="submit">
          Add
        </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddCategoryDiscount;
