import { React, useState } from "react";
import { Document, Page } from "react-pdf";
import { Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../components/StackedChart";
import { DataGrid } from "@mui/x-data-grid";
import { pdffile } from "../../assets/images/Admin.pdf";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
  MenuDots,
  Tick,
  PaymentTick,
} from "../../constants/svg";
import { Assets } from "../../constants/images";
import LineGraph from "../../components/LineGraph";
import CareGraph from "../../components/CareGraph";
import TotalPlansGraph from "../../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../../components/Statistics";
import DashboardSidebar from "../../components/DashboardSidebar";
import ProfileBox from "../../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import ThemeModal from "../../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../../components/TopBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FollowUp from "../../components/Modals/Marketing/FollowUp";

const ProposalProfile = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [showfollowup, setShowFollowUp] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const userActions = (rowData) => {
    return (
      <DropdownButton
        align="end"
        className={"actionDropdown"}
        id="dropdown-item-button"
        title={<MenuDots />}
      >
        <Dropdown.Item as="button">Edit</Dropdown.Item>
        <Dropdown.Item as="button">Delete</Dropdown.Item>
      </DropdownButton>
    );
  };

  const columns = [
    {
      field: "UserId",
      headerName: "Appointment ID",
      width: 145,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "description",
      headerName: "Descriptions",
      width: 145,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "time",
      headerName: "Total Time",
      width: 145,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cost",
      headerName: "Total Cost",
      width: 145,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "interval",
      headerName: "Interval",
      width: 145,
      align: "center",
      headerAlign: "center",
    },
  ];

  const rows = [
    {
      id: 1,
      UserId: "01",
      description: "lorem ipsum dolor sit amet consectetur adipisicing elit,",
      time: "45 Mins",
      cost: "$1000",
      interval: "$1000",
    },

    {
      id: 2,
      UserId: "02",
      description: "lorem ipsum dolor sit amet consectetur adipisicing elit,",
      time: "45 Mins",
      cost: "$1000",
      interval: "$1000",
    },

    {
      id: 3,
      UserId: "03",
      description: "lorem ipsum dolor sit amet consectetur adipisicing elit,",
      time: "45 Mins",
      cost: "$1000",
      interval: "$1000",
    },
    {
      id: 4,
      UserId: "04",
      description: "lorem ipsum dolor sit amet consectetur adipisicing elit,",
      time: "45 Mins",
      cost: "$1000",
      interval: "$1000",
    },
    {
      id: 5,
      UserId: "05",
      description: "lorem ipsum dolor sit amet consectetur adipisicing elit,",
      time: "45 Mins",
      cost: "$1000",
      interval: "$1000",
    },
    {
      id: 6,
      UserId: "06",
      description: "lorem ipsum dolor sit amet consectetur adipisicing elit,",
      time: "45 Mins",
      cost: "$1000",
      interval: "$1000",
    },
    {
      id: 7,
      UserId: "07",
      description: "lorem ipsum dolor sit amet consectetur adipisicing elit,",
      time: "45 Mins",
      cost: "$1000",
      interval: "$1000",
    },
    {
      id: 8,
      UserId: "08",
      description: "lorem ipsum dolor sit amet consectetur adipisicing elit,",
      time: "45 Mins",
      cost: "$1000",
      interval: "$1000",
    },
    {
      id: 9,
      UserId: "09",
      description: "lorem ipsum dolor sit amet consectetur adipisicing elit,",
      time: "45 Mins",
      cost: "$1000",
      interval: "$1000",
    },
  ];

  return (
    <div className="user-management proposal-profile">
      <Row>
        <Col md={12}>
          <div className="breadcrumbs">
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/dashboard">Dashboard</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <NavLink to="/Marketing/ProposalProfile">Proposal Profile</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h2>Proposal Profile</h2>
        </Col>
        <Col md={6}>
          <div className="d-flex justify-content-end">
            <div>
              <Button className="filter-btn d-none">Filter</Button>
            </div>
            <div>
              <Button
                onClick={() => setShowFollowUp(true)}
                className="add-new-btn ms-3"
              >
                Follow Up
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={9}>
              <div className="dental-plan">
                <div className="dental-plan-heading">Dental Plan</div>
                <div className="dental-desc">
                  lorem ipsum dolor sit amet, lorem ipsum dolor sit amet,
                  consectetur.
                </div>
                <div className="d-lg-flex d-block">
                  <div className="d-flex ms-2 ms-lg-0">
                    <div className="duration">Duration:</div>
                    <div className="months">3 Months</div>
                  </div>

                  <div className="d-flex ms-2">
                    <div className="duration">Total Appointments:</div>
                    <div className="months">08</div>
                  </div>

                  <div className="d-flex ms-2">
                    <div className="duration">Total Fees:</div>
                    <div className="months">75%</div>
                  </div>

                  <div className="d-flex ms-2">
                    <div className="duration">Discount</div>
                    <div className="months">25%</div>
                  </div>

                  <div className="d-flex ms-2">
                    <div className="duration">Insurance</div>
                    <div className="months">25%</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="contract">
                <div className="contract-heading">Contract</div>
                <div>
                  <Document
                    file="../../assets/images/Admin.pdf"
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={9}>
          <div className="status-container">
            <div className="default-heading">Status</div>
            <div className="d-flex justify-content-center">
              <div>
                <div className="status">Created</div>
                <div className="status-response">24/8/2022</div>
                <div className="status-response">12:30 pm</div>
              </div>
              <div className="ms-3">
                <div className="status">Sent</div>
                <div className="status-response">24/8/2022</div>
                <div className="status-response">12:30 pm</div>
              </div>
              <div className="ms-3">
                <div className="status">Seen</div>
                <div className="status-response">24/8/2022</div>
                <div className="status-response">12:30 pm</div>
              </div>
              <div className="ms-3">
                <div className="status">Sales Rep Connected</div>
                <div className="status-response">24/8/2022</div>
                <div className="status-response">12:30 pm</div>
              </div>
              <div className="ms-3">
                <div className="status">Lead Converted</div>
                <div className="status-response">24/8/2022</div>
                <div className="status-response">12:30 pm</div>
              </div>
              <div className="ms-3">
                <div className="status">Signed</div>
                <div className="status-response">24/8/2022</div>
                <div className="status-response">12:30 pm</div>
              </div>
              <div className="ms-3">
                <div className="status">Paid</div>
                <div className="status-response">-</div>
              </div>
              <div className="ms-3">
                <div className="status">Connected</div>
                <div className="status-response">-</div>
              </div>
            </div>
          </div>
          <div className="cost-of-service">Cost of Services</div>
          <div className="cost-of-service-container">
            <div style={{ height: 650, width: "100%" }}>
              <DataGrid
                className="datagrid-table"
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[7]}
                checkboxSelection
              />
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div className="proposal-payment-plan">
            <div className="default-heading">Payment Plan</div>
            <div className="plan-no">Plan 1</div>
            <div className="plan-desc">
              lorem ipsum dolor sit amet, lorem ipsum dolor sit amet,
              consectetur.
            </div>
            <div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="plan-conditions">Payemnt Source: </div>
                <div className="payment-condition-answer">Split</div>
              </div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="plan-conditions">Amount Source: </div>
                <div className="payment-condition-answer">Adhoc</div>
              </div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="plan-conditions">Payemnt Mode: </div>
                <div className="payment-condition-answer">Automatic</div>
              </div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="plan-conditions">Total Discount: </div>
                <div className="payment-condition-answer">10%</div>
              </div>
            </div>
          </div>
          <div className="financing-plan-container">
            <div className="default-heading">Financing Plan</div>
            <div className="plan-no">Plan 1</div>
            <div className="plan-desc">
              lorem ipsum dolor sit amet, lorem ipsum dolor sit amet,
              consectetur.
            </div>
            <div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="pt_12">
                  <span className="plan-conditions">
                    Minimum Down Payment:{" "}
                  </span>
                  <span className="payment-condition-answer">75%</span>
                </div>
              </div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="pt_12">
                  <span className="plan-conditions">Interest Rate: </span>
                  <span className="payment-condition-answer">25%</span>
                </div>
              </div>{" "}
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="pt_12">
                  <span className="plan-conditions">
                    Interest Rate Adjustment:{" "}
                  </span>
                  <span className="payment-condition-answer">Required</span>
                </div>
              </div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="pt_12">
                  <span className="plan-conditions">Discount Eligiblity: </span>
                  <span className="payment-condition-answer">10%</span>
                </div>
              </div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="pt_12">
                  <span className="plan-conditions">
                    Calculation of Payment Schedule:
                  </span>
                  <span className="payment-condition-answer">Adhoc</span>
                </div>
              </div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="pt_12">
                  <span className="plan-conditions">Late Payment Fees:</span>
                  <span className="payment-condition-answer">$3000</span>
                </div>
              </div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="pt_12">
                  <span className="plan-conditions">
                    Insurance Claim Processing Fees:
                  </span>
                  <span className="payment-condition-answer">65%</span>
                </div>
              </div>
              <div className="d-flex mt_10">
                <div>
                  <PaymentTick />
                </div>
                <div className="pt_12">
                  <span className="plan-conditions">Extended Financing:</span>
                  <span className="payment-condition-answer">10 Months</span>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {/*FOLLOW UP MODAL */}
      <div className="pages-modals">
        <ThemeModal
          title={"Create Proposal"}
          content={<FollowUp />}
          show={showfollowup}
          onHide={() => setShowFollowUp(false)}
        />
      </div>
      {/* END OF FOLLOWUP MODAL*/}
    </div>
  );
};

export default ProposalProfile;
