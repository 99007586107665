import { React, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Eye, EyeClose, Tick } from "../../../constants/svg";
import { Assets } from "../../../constants/images";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
// import {FaEye, FaEyeSlash} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../../../constants/app-constants"


const AddFaq = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = data => console.log(data);
  return (
    <div className="user-modal">
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Question 1</Form.Label>
        <Form.Control as="textarea" rows={5}
                                    {...register("faq", {
                                      maxLength: {
                                        value: VALIDATIONS.ROLE_NAME,
                                        message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                                      },
                                      required: {
                                        value: true,
                                        message: "Question is required",
                                      },
                                    })}
                                  />
                                                   {
                                  errors.faq && 
                                  <span className="validations">{ errors.faq.message}</span>
                                 }
      </Form.Group>

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Answer</Form.Label>
        <Form.Control as="textarea" rows={5}
                                    {...register("faq", {
                                      maxLength: {
                                        value: VALIDATIONS.ROLE_NAME,
                                        message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                                      },
                                      required: {
                                        value: true,
                                        message: "Question is required",
                                      },
                                    })}
                                  />
                                                   {
                                  errors.faq && 
                                  <span className="validations">{ errors.faq.message}</span>
                                 }
      </Form.Group>

      <div className="text-end">
        <Button className="modal_submit_btn" variant="primary" type="submit">
          Add
        </Button>
      </div>
    </Form>

  </div>
  )
}

export default AddFaq