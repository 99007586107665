import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../components/StackedChart";
import { DataGrid } from "@mui/x-data-grid";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
  MenuDots
} from "../../constants/svg";
import { Assets } from "../../constants/images";
import LineGraph from "../../components/LineGraph";
import CareGraph from "../../components/CareGraph";
import TotalPlansGraph from "../../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../../components/Statistics";
import DashboardSidebar from "../../components/DashboardSidebar";
import ProfileBox from "../../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import ThemeModal from "../../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../../components/TopBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";


const AppointmentPage = () => {
  const userActions = (rowData) => {
    return (
      <DropdownButton
        align="end"
        className={"actionDropdown"}
        id="dropdown-item-button"
        title={<MenuDots />}
      >
        <Dropdown.Item as="button">Edit</Dropdown.Item>
        <Dropdown.Item as="button">Delete</Dropdown.Item>
      </DropdownButton>
    );
  };

  const columns = [
    {
      field: "UserId",
      headerName: "User ID",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "fullname",
      headerName: "Patient Name",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "doctor",
      headerName: "Doctor/Care Provider",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cdt",
      headerName: "CDT Name",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cdtcode",
      headerName: "CDT Code",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "time",
      headerName: "Time",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    
  ];

  const rows = [
    {
      id: 1,
      UserId: "01",
      fullname: "Paul Doe",
      doctor: "John",
      cdt: "Paul",
      cdtcode: "Patient",
      date: "02/12/2022",
      time: "02:00 PM"
    },

    {
      id: 2,
      UserId: "02",
      fullname: "John Doe",
      doctor: "John",
      cdt: "Paul",
      cdtcode: "Patient",
      date: "02/12/2022",
      time: "02:00 PM"
    },

    {
      id: 3,
      UserId: "03",
      fullname: "Ahmed",
      doctor: "John",
      cdt: "Paul",
      cdtcode: "Patient",
      date: "02/12/2022",
      time: "02:00 PM"
    },
    {
      id: 4,
      UserId: "04",
      fullname: "Mikaal",
      doctor: "John",
      cdt: "Paul",
      cdtcode: "Patient",
      date: "02/12/2022",
      time: "02:00 PM"
    },
    {
      id: 5,
      UserId: "05",
      fullname: "Michael Fiz",
      doctor: "John",
      cdt: "Paul",
      cdtcode: "Patient",
      date: "02/12/2022",
      time: "02:00 PM"
    },
    {
      id: 6,
      UserId: "06",
      fullname: "Laura Michel",
      doctor: "John",
      cdt: "Paul",
      cdtcode: "Patient",
      date: "02/12/2022",
      time: "02:00 PM"
    },
    {
      id: 7,
      UserId: "07",
      fullname: "Elijah William",
      doctor: "John",
      cdt: "Paul",
      cdtcode: "Patient",
      date: "02/12/2022",
      time: "02:00 PM"
    },
    {
      id: 8,
      UserId: "08",
      fullname: "Laura Michel",
      doctor: "John",
      cdt: "Paul",
      cdtcode: "Patient",
      date: "02/12/2022",
      time: "02:00 PM"
    },
    {
      id: 9,
      UserId: "09",
      fullname: "Elijah William",
      doctor: "John",
      cdt: "Paul",
      cdtcode: "Patient",
      date: "02/12/2022",
      time: "02:00 PM"
    },
  ];
  return (
    <div className="user-management">
      <Row>
        <Col md={12}>
          <div className='breadcrumbs'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/dashboard">Dashboard</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <NavLink to="/appointment">Appointment</NavLink>
            </Breadcrumb.Item>
          </Breadcrumb>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h2>Appointment</h2>
        </Col>
        <Col md={6}>
          <div className="d-flex justify-content-end d-none">
            <div>
              <Button className="filter-btn">Filter</Button>
            </div>
            <div>
              <Button className="add-new-btn ms-3">+ Add New</Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="table-container">
            <div style={{ height: 650, width: "100%" }}>
              <DataGrid
                className="datagrid-table"
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[7]}
                checkboxSelection
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AppointmentPage;
