import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Assets } from "../constants/images";
import { Link, NavLink, useLocation } from "react-router-dom";
import TotalPlansGraph from "../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
} from "../constants/svg";
import Modal from "react-bootstrap/Modal";
import LogoutModal from "./Modals/Logout/LogoutModal";

const ResponsiveNavbar = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
    const location = useLocation();
    console.log(location);

  return (
    <>
      <section className="Responsive_Navbar d-block d-xl-none">
        {" "}
        {[false].map((expand) => (
          <Navbar key={expand} bg="light" expand={expand} className="mb-3">
            <Container fluid>
              <Navbar.Brand>
                <Link to="/dashboard">
                  <img className="img-fluid" src={Assets.Logo} />
                </Link>
              </Navbar.Brand>
              <div className="d-flex text-end ms-auto mx-3">
                <div className="notification-box" onClick={handleShow}>
                  <DashboardNotification className="notif-icon" />
                </div>
                <div className="d-none d-sm-block">
                  <img
                    src={Assets.ProfileAvatar}
                    className="img-fluid res-img ms-2"
                  />
                </div>
              </div>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <Link to="/dashboard">
                      <img className="img-fluid" src={Assets.Logo} />
                    </Link>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3 res-nav-links">
                    {/* <Link to="/dashboard" className={location.pathname === "/dashboard" ? "active" : ""}> */}
                    <NavLink to="/dashboard">
                      <span>
                        <DashboardIcon />
                      </span>
                      <span className="ps-3">Dashboard</span>
                      </NavLink>
                    <NavLink to="/usermanagement">
                      <span>
                        <User />
                      </span>
                      <span className="ps-3">User</span>
                    </NavLink>
                    <NavLink to="/rolemanagement">
                      <span>
                        <Role />
                      </span>
                      <span className="ps-3">Role</span>
                    </NavLink>
                    <NavLink to="/marketing">
                      <span>
                        <Marketing />
                      </span>
                      <span className="ps-3">Marketing</span>
                    </NavLink>
                    <NavLink to="/paymentplan">
                      <span>
                        <Payment />
                      </span>
                      <span className="ps-3">Payment Plan</span>
                    </NavLink>
                    <NavLink to="/Financing">
                      <span>
                        <Financing />
                      </span>
                      <span className="ps-3">Financing Plan</span>
                    </NavLink>
                    <NavLink to="/appointment">
                      <span>
                        <Appointment />
                      </span>
                      <span className="ps-3">Appointment</span>
                    </NavLink>
                    <NavLink to="/ezpay/Dashboard">
                      <span>
                        <Ezpay />
                      </span>
                      <span className="ps-3">EZ Pay</span>
                    </NavLink>
                    <NavLink to="/EzPlan/">
                      <span>
                        <Ezplan />
                      </span>
                      <span className="ps-3">EZ Plan</span>
                    </NavLink>
                    <NavLink to="/administration">
                      <span>
                        <Administrator />
                      </span>
                      <span className="ps-3">Administration</span>
                    </NavLink>
                    <NavLink to="/content/termsandcondition">
                      <span>
                        <Content />
                      </span>
                      <span className="ps-3">Content</span>
                    </NavLink>
                    <NavLink to="/notification">
                      <span>
                        <Notification />
                      </span>
                      <span className="ps-3">Notification</span>
                    </NavLink>
                    <NavLink to="/logout">
                      <span>
                        <Logout />
                      </span>
                      <span className="ps-3">Logout</span>
                    </NavLink>
                  </Nav>
                  {/* <Box sx={{ minWidth: 120 }}>
                    <FormControl fullwidth>
                      <TextField
                        className="search-box"
                        label="Search"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton>
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Box> */}
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
        <Container>
          <Modal
            className="notification-modal"
            show={show}
            onHide={handleClose}
            size="md"
            aria-labelledby="contained-modal-title-center"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <div className="header d-flex w-100 align-items-center justify-content-between ">
                  <h6 className="p-0 m-0 modal-heading">
                    Notifications <span>(2)</span>
                  </h6>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="notification-container">
                <div className="notification-card mb-2 border-bottom pb-2">
                  <div
                    className={`notification-box  d-flex align-items-center p-3`}
                  >
                    <div>
                      <img src={Assets.AvatarOne} alt="Avatar" />
                    </div>
                    <div className="ms-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="d-block">
                          <b>Ashley Edward</b>
                        </span>
                      </div>
                      <p className="p-0 m-0">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Sequi, aliquam aliquid!
                      </p>
                      <small className="time-small">23m ago</small>
                    </div>
                  </div>
                  <div
                    className={`notification-box  d-flex align-items-center p-3`}
                  >
                    <div>
                      <img src={Assets.AvatarTwo} alt="Avatar" />
                    </div>
                    <div className="ms-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="d-block">
                          <b>Ashley Edward</b>
                        </span>
                      </div>
                      <p className="p-0 m-0">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Sequi, aliquam aliquid!
                      </p>
                      <small className="time-small">23m ago</small>
                    </div>
                  </div>
                  <div
                    className={`notification-box  d-flex align-items-center p-3`}
                  >
                    <div>
                      <img src={Assets.AvatarThree} alt="Avatar" />
                    </div>
                    <div className="ms-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="d-block">
                          <b>Ashley Edward</b>
                        </span>
                      </div>
                      <p className="p-0 m-0">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Sequi, aliquam aliquid!
                      </p>
                      <small className="time-small">23m ago</small>
                    </div>
                  </div>
                  <div
                    className={`notification-box  d-flex align-items-center p-3`}
                  >
                    <div>
                      <img src={Assets.AvatarOne} alt="Avatar" />
                    </div>
                    <div className="ms-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="d-block">
                          <b>Ashley Edward</b>
                        </span>
                      </div>
                      <p className="p-0 m-0">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Sequi, aliquam aliquid!
                      </p>
                      <small className="time-small">23m ago</small>
                    </div>
                  </div>
                  <div
                    className={`notification-box  d-flex align-items-center p-3`}
                  >
                    <div>
                      <img src={Assets.AvatarThree} alt="Avatar" />
                    </div>
                    <div className="ms-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="d-block">
                          <b>Ashley Edward</b>
                        </span>
                      </div>
                      <p className="p-0 m-0">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Sequi, aliquam aliquid!
                      </p>
                      <small className="time-small">23m ago</small>
                    </div>
                  </div>
                  <div
                    className={`notification-box  d-flex align-items-center p-3`}
                  >
                    <div>
                      <img src={Assets.AvatarTwo} alt="Avatar" />
                    </div>
                    <div className="ms-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="d-block">
                          <b>Ashley Edward</b>
                        </span>
                      </div>
                      <p className="p-0 m-0">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Sequi, aliquam aliquid!
                      </p>
                      <small className="time-small">23m ago</small>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      </section>
    </>
  );
};

export default ResponsiveNavbar;
