import { React, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Eye, EyeClose, Tick } from "../../../constants/svg";
import { Assets } from "../../../constants/images";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
// import {FaEye, FaEyeSlash} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../../../constants/app-constants"


const EditRole = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = data => console.log(data);
  return (
    <div className="user-modal validation-margin">
      <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="formBasicEmail">
          <Form.Label>Role Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Role Name"
            {...register("rolename", {
              maxLength: {
                value: VALIDATIONS.ROLE_NAME,
                message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
              },
              required: {
                value: true,
                message: VALIDATIONS_TEXT.ROLE_NAME_REQUIRED,
              },
            })}
          />
         {
          errors.rolename && 
          <span className="validations">{ errors.rolename.message}</span>
         }
        </Form.Group>
        <div className="text-end">
        <Button className="modal_submit_btn" variant="primary" type="submit">
          Update
        </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditRole;
