import { React, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Eye, EyeClose, Tick } from "../../../constants/svg";
import { Assets } from "../../../constants/images";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
// import {FaEye, FaEyeSlash} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";

const DeleteRecord = () => {
  return (
    <div className="user-modal">
    <p>Are you sure you want to deactivate this record</p>
    <div className="text-end">
    <Button className="modal_submit_btn">Deactivate</Button>
    <Button className="modal_cancel_btn">Cancel</Button>
    </div>
    </div>
  );
};

export default DeleteRecord;
