import {React,useState} from 'react';
import { Container, Row, Col } from "react-bootstrap";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import DropdownButton from "react-bootstrap/DropdownButton";
import ProfileBox from "../components/ProfileBox";
import {
    DashboardIcon,
    User,
    Role,
    Marketing,
    Payment,
    Financing,
    Appointment,
    Ezpay,
    Ezplan,
    Administrator,
    Content,
    Notification,
    TotalMonth,
    TotalPlans,
    TotalFinance,
    TotalCare,
    TotalPatients,
    Logout,
    SearchIcon,
    DashboardNotification,
    Profilearrow,
    SettingsIcon,
    LogoutProfile,
  } from "../constants/svg";
  import {Button} from "react-bootstrap"
  import { Assets } from "../constants/images";
  import { Link, NavLink } from "react-router-dom";
  import Modal from "react-bootstrap/Modal";
  import ThemeModal from "../components/ThemeModal";
  import Dropdown from "react-bootstrap/Dropdown";
  import LogoutModal from './Modals/Logout/LogoutModal';

const TopBar = () => {
    const [show, setShow] = useState(false);
    const [showlogout, setShowlogout] = useState(false);
    const handleLogout = () => setShowlogout(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    // const [notifShow, setNotifShow] = useState(false);
    // const [shownotif, setShowNotif] = useState(false);
    // const handleNotification = () => setShowNotif(false)
    // const handleNotifShow = () => setNotifShow(true);
    // const handleNotifClose = () => setNotifShow(false);

  
    function LogoutModalButtons() {
      return (
        <>
          <NavLink to="/">
            <Button
              className="modal_blue_btn"
              variant="primary"
              onClick={handleClose}
            >
              Logout
            </Button>
          </NavLink>
          <Button
            className="modal_gray_btn"
            variant="secondary"
            onClick={handleLogout}
          >
            Cancel
          </Button>
        </>
      );
    }
  
    
  return (
    <>
                  <Row>
                <Col md={6}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullwidth>
                      <TextField
                        className="search-box"
                        label="Search"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton>
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Box>
                </Col>
                <Col md={6}>
                  <div className="d-xl-flex mb-3 mb-md-0 justify-content-md-end text-end right-section d-none">
                    <div onClick={handleShow} className="notification-box">
                      <DashboardNotification className="notif-icon" />
                    </div>
                    <DropdownButton
                      className="three-dots-btn"
                      align="end"
                      title={<ProfileBox />}
                      id="dropdown-menu-align-end"
                    >
                      <Dropdown.Item>
                        <NavLink to="/settings/myprofile" className={"dropdown-link"}>
                          <span className="mx-2">
                            <SettingsIcon />
                          </span>
                          Settings
                        </NavLink>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <NavLink
                          to="#"
                          className={"dropdown-link"}
                          onClick={() => setShowlogout(true)}
                        >
                          <span className="mx-2">
                            <LogoutProfile />
                          </span>
                          Logout
                        </NavLink>
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </Col>
              </Row>

      {/* Notification Modal Starts Here */}
      <Modal
        className="notification-modal"
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-center"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="header d-flex w-100 align-items-center justify-content-between ">
              <h6 className="p-0 m-0 modal-heading">
                Notifications <span>(2)</span>
              </h6>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="notification-container">
            <div className="notification-card mb-2 border-bottom pb-2">
              <div
                className={`notification-box  d-flex align-items-center p-3`}
              >
                <div>
                  <img src={Assets.AvatarOne} alt="Avatar" />
                </div>
                <div className="ms-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="d-block">
                      <b>Ashley Edward</b>
                    </span>
                  </div>
                  <p className="p-0 m-0">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sequi, aliquam aliquid!
                  </p>
                  <small className="time-small">23m ago</small>
                </div>
              </div>
              <div
                className={`notification-box  d-flex align-items-center p-3`}
              >
                <div>
                  <img src={Assets.AvatarTwo} alt="Avatar" />
                </div>
                <div className="ms-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="d-block">
                      <b>Ashley Edward</b>
                    </span>
                  </div>
                  <p className="p-0 m-0">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sequi, aliquam aliquid!
                  </p>
                  <small className="time-small">23m ago</small>
                </div>
              </div>
              <div
                className={`notification-box  d-flex align-items-center p-3`}
              >
                <div>
                  <img src={Assets.AvatarThree} alt="Avatar" />
                </div>
                <div className="ms-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="d-block">
                      <b>Ashley Edward</b>
                    </span>
                  </div>
                  <p className="p-0 m-0">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sequi, aliquam aliquid!
                  </p>
                  <small className="time-small">23m ago</small>
                </div>
              </div>
              <div
                className={`notification-box  d-flex align-items-center p-3`}
              >
                <div>
                  <img src={Assets.AvatarOne} alt="Avatar" />
                </div>
                <div className="ms-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="d-block">
                      <b>Ashley Edward</b>
                    </span>
                  </div>
                  <p className="p-0 m-0">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sequi, aliquam aliquid!
                  </p>
                  <small className="time-small">23m ago</small>
                </div>
              </div>
              <div
                className={`notification-box  d-flex align-items-center p-3`}
              >
                <div>
                  <img src={Assets.AvatarThree} alt="Avatar" />
                </div>
                <div className="ms-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="d-block">
                      <b>Ashley Edward</b>
                    </span>
                  </div>
                  <p className="p-0 m-0">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sequi, aliquam aliquid!
                  </p>
                  <small className="time-small">23m ago</small>
                </div>
              </div>
              <div
                className={`notification-box  d-flex align-items-center p-3`}
              >
                <div>
                  <img src={Assets.AvatarTwo} alt="Avatar" />
                </div>
                <div className="ms-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="d-block">
                      <b>Ashley Edward</b>
                    </span>
                  </div>
                  <p className="p-0 m-0">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sequi, aliquam aliquid!
                  </p>
                  <small className="time-small">23m ago</small>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Notification Modal Ends Here */}

      {/* Logout MODAL STARTS HERE */}
      <ThemeModal
        className="ezfi-modal"
        title={"Alert"}
        content={<LogoutModal/>}
        show={showlogout}
        onHide={() => setShowlogout(false)}
      />
      {/* Logout MODAL ENDS HERE */}
              </>
  )
}

export default TopBar