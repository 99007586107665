import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../../components/StackedChart";
import { DataGrid } from "@mui/x-data-grid";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
  MenuDots,
  ThreeDots,
  GreenTick,
} from "../../../constants/svg";
import { Assets } from "../../../constants/images";
import LineGraph from "../../../components/LineGraph";
import CareGraph from "../../../components/CareGraph";
import TotalPlansGraph from "../../../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../../../components/Statistics";
import DashboardSidebar from "../../../components/DashboardSidebar";
import ProfileBox from "../../../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../../../components/ResponsiveNavbar";
import ThemeModal from "../../../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../../../components/TopBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PaymentCard from "../../../components/PaymentCard";
import FeaturedPlanCards from "../../../components/FeaturedPlanCards";

const FeaturedPlans = () => {
  const userActions = (rowData) => {
    return (
      <DropdownButton
        align="end"
        className={"actionDropdown"}
        id="dropdown-item-button"
        title={<MenuDots />}
      >
        <Dropdown.Item as="button">Edit</Dropdown.Item>
        <Dropdown.Item as="button">Delete</Dropdown.Item>
      </DropdownButton>
    );
  };

  const columns = [
    {
      field: "UserId",
      headerName: "User ID",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "membername",
      headerName: "Member Name",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "memberid",
      headerName: "Member ID",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: "Status",
      width: 120,
      id: "action",
      name: "Action",
      renderCell: (cellValues) => {
        return (
          <div className="table-status">
            Active
        </div>
        );
      },    
},
    {
      field: "renewalamount",
      headerName: "Renewal Amount",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "autorenewal",
      headerName: "Auto Renewal",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "renewaldate",
      headerName: "Renewal Date",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "pay",
      headerName: "Pay",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
  ];

  const rows = [
    {
      id: 1,
      UserId: "01",
      membername: "Paul Doe",
      memberid: "123",
      renewalamount: "$ 566",
      autorenewal: "On",
      renewaldate: "01/12/2022",
      pay: "abc"
    },

    {
      id: 2,
      UserId: "02",
      membername: "Paul Doe",
      memberid: "123",
      renewalamount: "$ 566",
      autorenewal: "On",
      renewaldate: "01/12/2022",
      pay: "abc"
    },

    {
      id: 3,
      UserId: "03",
      membername: "Paul Doe",
      memberid: "123",
      renewalamount: "$ 566",
      autorenewal: "On",
      renewaldate: "01/12/2022",
      pay: "abc"
    },
    {
      id: 4,
      UserId: "04",
      membername: "Paul Doe",
      memberid: "123",
      renewalamount: "$ 566",
      autorenewal: "On",
      renewaldate: "01/12/2022",
      pay: "abc"
    },
    {
      id: 5,
      UserId: "05",
      membername: "Paul Doe",
      memberid: "123",
      renewalamount: "$ 566",
      autorenewal: "On",
      renewaldate: "01/12/2022",
      pay: "abc"
    },
    {
      id: 6,
      UserId: "06",
      membername: "Paul Doe",
      memberid: "123",
      renewalamount: "$ 566",
      autorenewal: "On",
      renewaldate: "01/12/2022",
      pay: "abc"
    },
    {
      id: 7,
      UserId: "07",
      membername: "Paul Doe",
      memberid: "123",
      renewalamount: "$ 566",
      autorenewal: "On",
      renewaldate: "01/12/2022",
      pay: "abc"
    },
    {
      id: 8,
      UserId: "08",
      membername: "Paul Doe",
      memberid: "123",
      renewalamount: "$ 566",
      autorenewal: "On",
      renewaldate: "01/12/2022",
      pay: "abc"
    },
    {
      id: 9,
      UserId: "09",
      membername: "Paul Doe",
      memberid: "123",
      renewalamount: "$ 566",
      autorenewal: "On",
      renewaldate: "01/12/2022",
      pay: "abc"
    },
  ];

  const items = ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5"];

  return (
    <>
      <div className="user-management">
        <Row>
          <Col md={12}>
            <div className="breadcrumbs">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <NavLink to="/dashboard">Dashboard</NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <NavLink to="/EzPlan/">EZ Plan Dashboard</NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <NavLink to="/EzPlan/featured-plans">Membership Plan</NavLink>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h2 className="mb-0">Membership Plan</h2>
          </Col>
          <Col md={6}>
            <div className="d-flex justify-content-end">
              <div>
                <Button className="filter-btn d-none">Filter</Button>
              </div>
              <div>
                <NavLink to="/EzPlan/membership-plan">
                <Button className="add-new-btn ms-3">+ Create New Plan</Button>
                </NavLink>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="featured-plans">Featured Plans</div>
          </Col>
        </Row>

        <Row>
          <FeaturedPlanCards />
        </Row>
      </div>

      <div className="user-management">
        <Row>
          <Col md={12}>
            <div className="table-container table-padding">
              <Row>
                <Col md={6}>
                  <div className="membership-plan">Membership Plans</div>
                </Col>
                <Col md={6}>
                  <div className="d-flex justify-content-end">
                    <div>
                      <NavLink to="/EzPlan/view-all-membership-plan">
                      <Button className="view-membership-btn">View All Membership Plan</Button>
                      </NavLink>
                    </div>
                  </div>
                </Col>
              </Row>
              <div style={{ height: 650, width: "100%" }}>
                <DataGrid
                  className="datagrid-table"
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[7]}
                  checkboxSelection
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FeaturedPlans;
