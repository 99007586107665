import { React, useState } from "react";
import { Modal, Container } from "react-bootstrap";
function ThemeModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`customModal ${props?.className}`}
    >
        <div className="modal_main_content">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.content}</Modal.Body>
        <Modal.Footer>{props.footer}</Modal.Footer>
        </div>
    </Modal>
  );
}
export default ThemeModal;
