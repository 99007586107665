import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../../components/StackedChart";
import { DataGrid } from "@mui/x-data-grid";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
  MenuDots,
  FileUpload,
  UserIcon,
} from "../../../constants/svg";
import { Assets } from "../../../constants/images";
import LineGraph from "../../../components/LineGraph";
import CareGraph from "../../../components/CareGraph";
import TotalPlansGraph from "../../../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../../../components/Statistics";
import DashboardSidebar from "../../../components/DashboardSidebar";
import ProfileBox from "../../../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../../../components/ResponsiveNavbar";
import ThemeModal from "../../../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../../../components/TopBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import AddFeeSchedule from "../../../components/Modals/FeeSchedule/AddFeeSchedule";
import DeleteRecord from "../../../components/Modals/RoleManagement/DeleteRecord";

const FeeSchedule = () => {

  const [show, setShow] = useState(false);
  const [showaddnew, setShowAddNew] = useState(false);
  const handleAddNew = () => setShowAddNew(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [showdelete, setShowDelete] = useState(false);
  const [showeditnew, setShowEditNew] = useState(false);
  const handleEditNew = () => setShowEditNew(false);

  const userActions = (rowData) => {
    return (
      <DropdownButton
        align="end"
        className={"actionDropdown"}
        id="dropdown-item-button"
        title={<MenuDots />}
      >
        <Dropdown.Item as="button">Edit</Dropdown.Item>
        <Dropdown.Item as="button">Delete</Dropdown.Item>
      </DropdownButton>
    );
  };

  const columns = [
    {
      field: "UserId",
      headerName: "User ID",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cdtcode",
      headerName: "CDT Code",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "category",
      headerName: "Category",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ucrfee",
      headerName: "UCR Fee",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "dsofee",
      headerName: "DSO Fee",
      width: 140,
      align: "center",
      headerAlign: "center",
    },

    {
      headerName: "Action",
      width: 120,
      id: "action",
      name: "Action",
      renderCell: (cellValues) => {
        return (
          <div className="user-button">
          <DropdownButton
            id="dropdown-basic-button"
            title={<UserIcon/>}
          >
            <Dropdown.Item
            onClick={() => setShowEditNew(true)}
            href="#/action-1">Edit</Dropdown.Item>
            <Dropdown.Item 
            onClick={() => setShowDelete(true)}
            href="#/action-2">
              Delete
            </Dropdown.Item>
          </DropdownButton>
        </div>
        );
      }, }
  ];

  const rows = [
    {
      id: 1,
      UserId: "01",
      cdtcode: "A",
      description: "Lorem ipsum dolor sit amet, consectetur.",
      category: "A",
      ucrfee: "$455",
      dsofee: "$455",
    },

    {
      id: 2,
      UserId: "02",
      cdtcode: "B",
      description: "Lorem ipsum dolor sit amet, consectetur.",
      category: "B",
      ucrfee: "$455",
      dsofee: "$455",


    },

    {
      id: 3,
      UserId: "03",
      cdtcode: "C",
      description: "Lorem ipsum dolor sit amet, consectetur.",
      category: "C",
      ucrfee: "$455",
      dsofee: "$455",


    },
    {
      id: 4,
      UserId: "04",
      cdtcode: "D",
      description: "Lorem ipsum dolor sit amet, consectetur.",
      category: "D",
      ucrfee: "$455",
      dsofee: "$455",

    },
    {
      id: 5,
      UserId: "05",
      cdtcode: "E",
      description: "Lorem ipsum dolor sit amet, consectetur.",
      category: "E",
      ucrfee: "$455",
      dsofee: "$455",

    },
    {
      id: 6,
      UserId: "06",
      cdtcode: "F",
      description: "Lorem ipsum dolor sit amet, consectetur.",
      category: "F",
      ucrfee: "$455",
      dsofee: "$455",

    },
    {
      id: 7,
      UserId: "07",
      cdtcode: "G",
      description: "Lorem ipsum dolor sit amet, consectetur.",
      category: "G",
      ucrfee: "$455",
      dsofee: "$455",

    },
    {
      id: 8,
      UserId: "08",
      cdtcode: "H",
      description: "Lorem ipsum dolor sit amet, consectetur.",
      category: "H",
      ucrfee: "$455",
      dsofee: "$455",

    },
    {
      id: 9,
      UserId: "09",
      cdtcode: "I",
      description: "Lorem ipsum dolor sit amet, consectetur.",
      category: "I",
      ucrfee: "$455",
      dsofee: "$455",

    },
  ];

  return (
    <div className="user-management">
      <Row>
        <Col md={12}>
          <div className="breadcrumbs">
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/dashboard">Dashboard</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink to="/EzPlan/">Ez Plan Dashboard</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <NavLink to="/EzPlan/FeeSchedule">Fee Schedule</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h2>Fee Schedule</h2>
        </Col>
        <Col md={6}>
          <div className="d-flex justify-content-end">
            <div>
              <Button className="fee-schedule-upload-btn">
                <FileUpload />
                <span className="ms-2">Upload File</span>
              </Button>
            </div>
            <div>
              <Button
                onClick={() => setShowAddNew(true)}
               className="add-new-btn ms-3">+ Add CDT Code</Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="table-container">
            <div style={{ height: 650, width: "100%" }}>
              <DataGrid
                className="datagrid-table"
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[7]}
                checkboxSelection
              />
            </div>
          </div>
        </Col>
      </Row>

                  {/* USER MANAGEMENT MODAL STARTS HERE */}

      {/* ADD USER MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Add CDT Code"}
          content={<AddFeeSchedule/>}
          // footer={<LogoutModalButtons />}
          show={showaddnew}
          onHide={() => setShowAddNew(false)}
        />
      </div>
      {/* END OF ADD USER MODAL */}

      {/* Edit USER MODAL */}
      <ThemeModal
        className="ezfi-modal"
        title={"Edit CDT Code"}
        content={<AddFeeSchedule/>}
        // footer={<LogoutModalButtons />}
        show={showeditnew}
        onHide={() => setShowEditNew(false)}
      />
      {/* END OF Edit USER MODAL */}

      {/* EDIT USER MODAL */}
      {/* END OF EDIT USER MODAL */}

      {/* Delete User MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Alert"}
          content={<DeleteRecord/>}
          show={showdelete}
          onHide={() => setShowDelete(false)}
        />
      </div>
      {/* END OF Delete User MODAL */}
      {/* USER MANAGEMENT MODAL ENDS HERE */}


    </div>
  );
};

export default FeeSchedule;
