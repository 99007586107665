import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../components/StackedChart";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
} from "../constants/svg";
import { Assets } from "../constants/images";
import LineGraph from "../components/LineGraph";
import CareGraph from "../components/CareGraph";
import TotalPlansGraph from "../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../components/Statistics";
import DashboardSidebar from "../components/DashboardSidebar";
import ProfileBox from "../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../components/ResponsiveNavbar";
import ThemeModal from "../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../components/TopBar";

const Dashboard = () => {
  return (
    <div>
              {/* Statistics Section Starts Here */}
              <Statistics />
              {/* Statistics Section Ends Here */}

              {/* Patient Section Starts Here */}
              <div className="patient-section all-section-top-gap">
                <LineGraph />
              </div>
              {/* Patient Section Ends Here */}

              {/* Three Sections Starts Here */}
              <Row>
                <Col lg={4} className="all-section-top-gap">
                  {/* Care Section Starts Here */}
                  <div className="care-section">
                    <Row>
                      <Col sm={7} md={8}>
                        <div className="care mt-2 pb-3">Care Providers</div>
                      </Col>
                      <Col sm={5} md={4}>
                        <Dropdown className="text-start text-sm-end ms-4 ms-sm-0">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            Monthly
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              Weekly
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Daily
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                    <CareGraph />
                  </div>
                  {/* Care Section Ends Here*/}
                </Col>{" "}
                <Col lg={4} className="all-section-top-gap">
                  {/* Care Section Starts Here */}
                  <div className="plan-section">
                    <div className="care mt-2 pb-3">Total Plans</div>
                    <TotalPlansGraph />
                  </div>
                  {/* Care Section Ends Here*/}
                </Col>{" "}
                <Col lg={4} className="all-section-top-gap">
                  {/* Care Section Starts Here */}
                  <div className="care-section">
                    <Row>
                      <Col md={8} sm={6}>
                        <div className="care mt-2 pb-3">Plans</div>
                      </Col>
                      <Col md={4} sm={6}>
                        <Dropdown className="text-start text-sm-end ms-4 ms-sm-0">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            This Week
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item>
                              Previous Week
                            </Dropdown.Item>
                            <Dropdown.Item>
                              Next Week
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>{" "}
                    <StackedChart />
                  </div>
                  {/* Care Section Ends Here*/}
                </Col>
              </Row>
    </div>
  );
};

export default Dashboard;
