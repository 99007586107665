import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../../components/StackedChart";
import { DataGrid } from "@mui/x-data-grid";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
  MenuDots,
  ThreeDots,
  GreenTick,
} from "../../../constants/svg";
import { Assets } from "../../../constants/images";
import LineGraph from "../../../components/LineGraph";
import CareGraph from "../../../components/CareGraph";
import TotalPlansGraph from "../../../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../../../components/Statistics";
import DashboardSidebar from "../../../components/DashboardSidebar";
import ProfileBox from "../../../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../../../components/ResponsiveNavbar";
import ThemeModal from "../../../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../../../components/TopBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PaymentCard from "../../../components/PaymentCard";
import PieChart from "../../../components/PieChart";

const EzPlanDashboard = () => {
  return (
    <div className="user-management">
      <Row>
        <Col md={12}>
          <div className="breadcrumbs">
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/dashboard">Dashboard</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <NavLink to="/EzPlan/">Ez Plan Dashboard</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h2>Ez Plan Dashboard</h2>
        </Col>
        <Col md={6}>
          <div className="d-flex justify-content-end d-none">
            <div>
              <Button className="filter-btn d-none">Filter</Button>
            </div>
            <div>
              <Button className="add-new-btn ms-3">+ Add New</Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="ez-statistics-container">
          <div className="heading">Statistics</div>
          <Row>
            <Col md={4} lg={2} sm={6}>
              <div className="d-flex">
                <div>
                  <img className="img-fluid" src={Assets.EzIconOne} />
                </div>
                <div className="ml_20">
                  <h3>8.649K</h3>
                  <p>Active Membership Plans</p>
                </div>
              </div>
            </Col>
            <Col md={4} lg={2} sm={6}>
              <div className="d-flex">
                <div>
                  <img className="img-fluid" src={Assets.EzIconTwo} />
                </div>
                <div className="ml_20">
                  <h3>230K</h3>
                  <p>Net Sales</p>
                </div>
              </div>
            </Col>
            <Col md={4} lg={2} sm={6}>
              <div className="d-flex">
                <div>
                  <img className="img-fluid" src={Assets.EzIconThree} />
                </div>
                <div className="ml_20">
                  <h3>1.423K</h3>
                  <p>Total New Members</p>
                </div>
              </div>
            </Col>
            <Col md={4} lg={2} sm={6}>
              <div className="d-flex">
                <div>
                  <img className="img-fluid" src={Assets.EzIconFour} />
                </div>
                <div className="ml_20">
                  <h3>1.423K</h3>
                  <p>Total Membership</p>
                </div>
              </div>
            </Col>
            <Col md={4} lg={2} sm={6}>
              <div className="d-flex">
                <div>
                  <img className="img-fluid" src={Assets.EzIconFive} />
                </div>
                <div className="ml_20">
                  <h3>$9745</h3>
                  <p>Revenue Previous</p>
                </div>
              </div>
            </Col>
            <Col md={4} lg={2} sm={6}>
              <div className="d-flex">
                <div>
                  <img className="img-fluid" src={Assets.EzIconSix} />
                </div>
                <div className="ml_20">
                  <h3>$9745</h3>
                  <p>Total Recurring Results</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col md={6}>
              <div className="ez-graph-container">
                <h3>Total Plans</h3>
                <div className="graph-section">
                <TotalPlansGraph/>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="ez-graph-container">
                <h3>Revenue Comparison</h3>
                <div className="graph-section">
                <PieChart />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  );
};

export default EzPlanDashboard;
