import { React, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Eye, EyeClose, Tick } from "../../../constants/svg";
import { Assets } from "../../../constants/images";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
// import {FaEye, FaEyeSlash} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";

const FollowUp = () => {
  return (
    <div className="user-modal">
      <Form>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Full Name</Form.Label>
          <Form.Control type="email" placeholder="Enter full name" />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter email" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Message</Form.Label>
          <Form.Control as="textarea" rows={4} />
        </Form.Group>
        <div className="text-end">
          <Button className="modal_submit_btn" variant="primary" type="submit">
            Follow Up
          </Button>
        </div>
      </Form>

    </div>
  );
};

export default FollowUp;
