import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../components/StackedChart";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  SettingsIcon,
  LogoutProfile,
  DeleteIcon,
  LockIcon,
  ProfileIcon,
  EditIcon
} from "../../constants/svg";
import { Assets } from "../../constants/images";
import LineGraph from "../../components/LineGraph";
import CareGraph from "../../components/CareGraph";
import TotalPlansGraph from "../../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../../components/Statistics";
import DashboardSidebar from "../../components/DashboardSidebar";
import ProfileBox from "../../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import ThemeModal from "../../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../../components/TopBar";
const MyProfile = () => {
  return (
    <>
    <div className='profile-component'>
                    <Row>
                      <Col md={8}>
                        <div className='profile-title'>
                          <span><img src={Assets.AvatarSetting}/></span><span className="profile-name">Alex Joe</span>
                        </div>
                        <div className='profile-details'>
                          <div className='d-flex'>
                          <div className="role">Role:</div><div className="admin">Admin</div>
                          </div>
                          <div className='d-flex mt_17'>
                          <div className="role">Email:</div><div className="admin">alex.doe@gmail.com</div>
                          </div>
                          <div className='d-flex mt_17'>
                          <div className="role">Phone No:</div><div className="admin">+1 675 346 23-10</div>
                          </div>
                          <div className='d-flex mt_17'>
                          <div className="role">Address:</div><div className="admin">2681 Jail Drive</div>
                          </div>
                          <div className='d-flex mt_17'>
                          <div className="role">ZIP Code:</div><div className="admin">90272</div>
                          </div>
                          <div className='d-flex mt_17'>
                          <div className="role">State</div><div className="admin">California</div>
                          </div>
                          <div className='d-flex mt_17'>
                          <div className="role">Country</div><div className="admin">United States of America</div>
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="text-end edit-icon">
                        <EditIcon className='edit-icon'/>
                        </div>
                      </Col>
                    </Row>
    </div>
    </>
  )
}

export default MyProfile