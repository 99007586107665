import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../components/StackedChart";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  SettingsIcon,
  LogoutProfile,
  DeleteIcon,
  LockIcon,
  ProfileIcon,
  EditIcon,
  TermsIcon,
  FaqIcon,
  PrivacyIcon,
} from "../../constants/svg";
import { Assets } from "../../constants/images";
import LineGraph from "../../components/LineGraph";
import CareGraph from "../../components/CareGraph";
import TotalPlansGraph from "../../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../../components/Statistics";
import DashboardSidebar from "../../components/DashboardSidebar";
import ProfileBox from "../../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import ThemeModal from "../../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../../components/TopBar";
import FaqAccordion from "../FaqAccordion";
import AddUser from "../Modals/UserManagement/AddUser";
import AddFaq from "../Modals/FAQ/AddFaq";
const Faq = () => {
  const [show, setShow] = useState(false);
  const [showaddnew, setShowAddNew] = useState(false);
  const handleAddNew = () => setShowAddNew(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [showdelete, setShowDelete] = useState(false);

  // edit modal
  const [showeditnew, setShowEditNew] = useState(false);
  const handleEditNew = () => setShowEditNew(false);
  return (
    <div className="content-management">
      <Row>
        <Col md={8} sm={8}>
          <div className="profile-title">
            <h2>FAQs</h2>
          </div>
        </Col>
        <Col md={4} sm={4}>
          <div className="text-start text-sm-end mt-3 mb-3 mt-sm-0 mb-sm-0">
            <Button onClick={() => setShowAddNew(true)} className="add-new-btn">+ Add New</Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FaqAccordion />
        </Col>
      </Row>

      {/* ADD USER MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Add New Faq"}
          content={<AddFaq/>}
          // footer={<LogoutModalButtons />}
          show={showaddnew}
          onHide={() => setShowAddNew(false)}
        />
      </div>
      {/* END OF ADD USER MODAL */}

            {/* ADD USER MODAL */}
            <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Edit Faq"}
          content={<AddFaq/>}
          // footer={<LogoutModalButtons />}
          show={showeditnew}
          onHide={() => setShowEditNew(false)}
        />
      </div>
      {/* END OF ADD USER MODAL */}

    </div>
  );
};

export default Faq;
