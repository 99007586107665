import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../components/StackedChart";
import { DataGrid } from "@mui/x-data-grid";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
  MenuDots,
  EyeHide,
  UserIcon,
} from "../../constants/svg";
import { Assets } from "../../constants/images";
import LineGraph from "../../components/LineGraph";
import CareGraph from "../../components/CareGraph";
import TotalPlansGraph from "../../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../../components/Statistics";
import DashboardSidebar from "../../components/DashboardSidebar";
import ProfileBox from "../../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import ThemeModal from "../../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../../components/TopBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import AddRole from "../../components/Modals/RoleManagement/AddRole";
import EditRole from "../../components/Modals/RoleManagement/EditRole";
import DeleteRecord from "../../components/Modals/RoleManagement/DeleteRecord";

const RoleManagement = () => {
  const [show, setShow] = useState(false);
  const [showaddnew, setShowAddNew] = useState(false);
  const handleAddNew = () => setShowAddNew(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [showrole, setShowRole] = useState(false);
  const handleRole = () => setShowRole(false);
  const [showeditrole, setShowEditRole] = useState(false);
  const [showdelete, setShowDelete] = useState(false);

  const columns = [
    {
      field: "UserId",
      headerName: "User ID",
      width: 250,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "role",
      headerName: "Role",
      width: 250,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "users",
      headerName: "No of Users",
      width: 250,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: "Action",
      width: 200,
      id: "action",
      name: "Action",
      renderCell: (cellValues) => {
        return (
          <div className="user-button">
            <DropdownButton id="dropdown-basic-button" title={<UserIcon />}>
              <Dropdown.Item
                onClick={() => setShowEditRole(true)}
                href="#/action-1"
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setShowDelete(true)}
                href="#/action-2"
              >
                Deactivate
              </Dropdown.Item>
            </DropdownButton>
          </div>
        );
      },
    },
  ];

  const rows = [
    {
      id: 1,
      UserId: "01",
      role: "Patient",
      users: "3",
    },

    {
      id: 2,
      UserId: "02",
      role: "CareProvider",
      users: "4",
    },
  ];

  return (
    <div className="user-management">
      <Row>
        <Col md={12}>
          <div className="breadcrumbs">
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/dashboard">Dashboard</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <NavLink to="/rolemanagement">Role Management</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={8} sm={8}>
          <h2>Role Management</h2>
        </Col>
        <Col md={4} sm={4}>
          <div className="d-flex justify-content-start justify-content-sm-end ">
            <div>
              <Button
                onClick={() => setShowDelete(true)}
                className="eyehide-bg"
              >
                <EyeHide />
              </Button>
            </div>
            <div>
              <Button
                onClick={() => setShowRole(true)}
                className="add-new-btn ms-3"
              >
                + Add New
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="table-container">
            <div style={{ height: 650, width: "100%" }}>
              <DataGrid
                className="datagrid-table"
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[7]}
                checkboxSelection
              />
            </div>
          </div>
        </Col>
      </Row>

      {/* ADD Role MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Add Role"}
          content={<AddRole />}
          // footer={<LogoutModalButtons />}
          show={showrole}
          onHide={() => setShowRole(false)}
        />
      </div>
      {/* END OF ADD Role MODAL */}

      {/* Edit Role MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Edit Role"}
          content={<EditRole />}
          show={showeditrole}
          onHide={() => setShowEditRole(false)}
        />
      </div>
      {/* END OF Edit Role MODAL */}

      {/* Delete Role MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Alert"}
          content={<DeleteRecord />}
          show={showdelete}
          onHide={() => setShowDelete(false)}
        />
      </div>
      {/* END OF Delete Role MODAL */}
    </div>
  );
};

export default RoleManagement;
