import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../components/StackedChart";
import { DataGrid } from "@mui/x-data-grid";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
  MenuDots,
  UserIcon,
} from "../../constants/svg";
import { Assets } from "../../constants/images";
import LineGraph from "../../components/LineGraph";
import CareGraph from "../../components/CareGraph";
import TotalPlansGraph from "../../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../../components/Statistics";
import DashboardSidebar from "../../components/DashboardSidebar";
import ProfileBox from "../../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import ThemeModal from "../../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../../components/TopBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import CreateNewPlan from "../../components/Modals/Administration/CreateNewPlan";
import EditPlan from "../../components/Modals/Administration/EditPlan";
import DeleteRecord from "../../components/Modals/RoleManagement/DeleteRecord";

const Administration = () => {
  const [show, setShow] = useState(false);
  const [showaddnew, setShowAddNew] = useState(false);
  const handleAddNew = () => setShowAddNew(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [showdelete, setShowDelete] = useState(false);

  // edit modal
  const [showeditnew, setShowEditNew] = useState(false);
  const handleEditNew = () => setShowEditNew(false);

  const columns = [
    {
      field: "UserId",
      headerName: "User ID",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "fullname",
      headerName: "Plan Name",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "fee",
      headerName: "Fees / Month",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "date",
      headerName: "Next Payment Date",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "users",
      headerName: "Total No of Users",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastbilled",
      headerName: "Last Billed",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "transactionid",
      headerName: "Transaction Id",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: "Action",
      width: 200,
      id: "action",
      name: "Action",
      renderCell: (cellValues) => {
        return (
          <div className="user-button">
            <DropdownButton id="dropdown-basic-button" title={<UserIcon />}>
              <Dropdown.Item
                
                onClick={() => setShowEditNew(true)}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
              onClick={() => setShowDelete(true)}

                             >
                Deactivate
              </Dropdown.Item>
            </DropdownButton>
          </div>
        );
      },
    },

  ];

  const rows = [
    {
      id: 1,
      UserId: "01",
      fullname: "Dental",
      fee: "$455",
      date: "14/08/2022",
      users: "42",
      lastbilled: "$455",
      transactionid: "123",
    },

    {
      id: 2,
      UserId: "02",
      fullname: "ENT",
      fee: "$455",
      date: "14/08/2022",
      users: "42",
      lastbilled: "$455",
      transactionid: "123",
    },

    {
      id: 3,
      UserId: "03",
      fullname: "Heart",
      fee: "$455",
      date: "14/08/2022",
      users: "42",
      lastbilled: "$455",
      transactionid: "123",
    },
    {
      id: 4,
      UserId: "04",
      fullname: "Dental",
      fee: "$455",
      date: "14/08/2022",
      users: "42",
      lastbilled: "$455",
      transactionid: "123",
    },
    {
      id: 5,
      UserId: "05",
      fullname: "Michael Fiz",
      fee: "$455",
      date: "14/08/2022",
      users: "42",
      lastbilled: "$455",
      transactionid: "123",
    },
    {
      id: 6,
      UserId: "06",
      fullname: "Dental",
      fee: "$455",
      date: "14/08/2022",
      users: "42",
      lastbilled: "$455",
      transactionid: "123",
    },
    {
      id: 7,
      UserId: "07",
      fullname: "Heart",
      fee: "$455",
      date: "14/08/2022",
      users: "42",
      lastbilled: "$455",
      transactionid: "123",
    },
    {
      id: 8,
      UserId: "08",
      fullname: "Dental",
      fee: "$455",
      date: "14/08/2022",
      users: "42",
      lastbilled: "$455",
      transactionid: "123",
    },
    {
      id: 9,
      UserId: "09",
      fullname: "Heart",
      fee: "$455",
      date: "14/08/2022",
      users: "42",
      lastbilled: "$455",
      transactionid: "123",
    },
  ];

  return (
    <div className="user-management">
      <Row>
        <Col md={12}>
          <div className="breadcrumbs">
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/dashboard">Dashboard</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink to="/EzPlan/">EZ Plan Dashboard</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <NavLink to="/administration">Administration</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h2>Subscribed Users</h2>
        </Col>
        <Col md={6}>
          <div className="d-flex justify-content-start justify-content-md-end mb-3 mb-md-3">
            <div>
              <Button className="filter-btn d-none">Filter</Button>
            </div>
            <div>
              <Button
                onClick={() => setShowAddNew(true)}
                className="add-new-btn "
              >
                + Create New Plan
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="table-container">
            <div style={{ height: 650, width: "100%" }}>
              <DataGrid
                className="datagrid-table"
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[7]}
                checkboxSelection
              />
            </div>
          </div>
        </Col>
      </Row>
      {/* ADMINISTRATION MODAL STARTS HERE */}

      {/* ADD USER MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Add User"}
          content={<CreateNewPlan />}
          // footer={<LogoutModalButtons />}
          show={showaddnew}
          onHide={() => setShowAddNew(false)}
        />
      </div>
      {/* END OF ADD USER MODAL */}

      {/* Edit USER MODAL */}
      <ThemeModal
        className="ezfi-modal"
        title={"Edit User"}
        content={<EditPlan />}
        // footer={<LogoutModalButtons />}
        show={showeditnew}
        onHide={() => setShowEditNew(false)}
      />
      {/* END OF Edit USER MODAL */}

      {/* EDIT USER MODAL */}
      {/* END OF EDIT USER MODAL */}

      {/* Delete User MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Alert"}
          content={<DeleteRecord />}
          show={showdelete}
          onHide={() => setShowDelete(false)}
        />
      </div>
      {/* END OF Delete User MODAL */}
      {/* ADMINISTRATION MODAL ENDS HERE */}
    </div>
  );
};

export default Administration;
