import React from "react";
import { ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";


const EzPayDonutChart = () => {

  const plugins = [{
    beforeDraw: function(chart) {
     var width = chart.width,
         height = chart.height,
         ctx = chart.ctx;
         ctx.restore();
         var fontSize = (height / 160).toFixed(2);
         ctx.font = fontSize + "em sans-serif";
         ctx.textBaseline = "top";
         var text = "38%",
         textX = Math.round((width - ctx.measureText(text).width) / 2),
         textY = height / 2.2;
         ctx.fillText(text, textX, textY);
         ctx.save();
    } 
  }]

  const options = {
    responsive: true,
    cutoutPercentage: 70,
    plugins: {
      legend: {
        display: false,
        position: 'bottom'
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
  };
  const data = {
    labels: ["Sales", "Sales", "Sales"],
    datasets: [
      {
        data: [250,250, 300],
        backgroundColor: ["#28C76F","#8fe2b4", "#d4f4e2"],
        hoverBackgroundColor: ["#28C76F","#8fe2b4", "#d4f4e2"],
        borderWidth: 0
      }
    ],
    
  };
  return (
    <div className="donutchart-container">
    <Doughnut options={options} data={data} plugins={plugins} />
    </div>
    )
}

export default EzPayDonutChart