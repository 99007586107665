import React from 'react';
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import faker from 'faker';

const EzPayLineGraph = () => {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
      );

    const options = {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'top'
          },
          title: {
            display: false,
            text: 'Chart.js Line Chart',
          },
        },
      };
      const labels = ['01', '02', '03'];


      const caredata = {
        labels: ["Jan", "Feb", "Mar","Apr"],
        datasets: [
          {
            label: "Active",
            data: [0,100,50,200],
            fill: true,
            backgroundColor: "rgba(0,207,232,0.2)",
            borderColor: "rgba(0,207,232,1)",

          },
        ]
      };
  
  return (
    <Line options={options} data={caredata} />
    )
}

export default EzPayLineGraph