import { React, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../components/StackedChart";
import { DataGrid } from "@mui/x-data-grid";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
  MenuDots,
  ThreeDots,
  GreenTick,
} from "../../constants/svg";
import { Assets } from "../../constants/images";
import LineGraph from "../../components/LineGraph";
import CareGraph from "../../components/CareGraph";
import TotalPlansGraph from "../../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../../components/Statistics";
import DashboardSidebar from "../../components/DashboardSidebar";
import ProfileBox from "../../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import ThemeModal from "../../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../../components/TopBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PaymentCard from "../../components/PaymentCard";
import PieChart from "../../components/PieChart";
import DateRange from "../../components/DateRange";
import EzPayRevenueGraph from "../../components/EzPayRevenueGraph";
import EzPayStackedChart from "../../components/EzPayStackedChart";
import EzPayLineGraph from "../../components/EzPayLineGraph";
import EzPayDonutChart from "../../components/EzPayDonutChart";

const EzPayDashboard = () => {

  const userActions = (rowData) => {
    return (
      <DropdownButton
        align="end"
        className={"actionDropdown"}
        id="dropdown-item-button"
        title={<MenuDots />}
      >
        <Dropdown.Item as="button">Edit</Dropdown.Item>
        <Dropdown.Item as="button">Delete</Dropdown.Item>
      </DropdownButton>
    );
  };

  const columns = [
    {
      field: "UserId",
      headerName: "User ID",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "fullname",
      headerName: "Full Name",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email Address",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "role",
      headerName: "Role",
      width: 130,
      align: "center",
      headerAlign: "center",
    },

  ];

  const rows = [
    {
      id: 1,
      UserId: "01",
      fullname: "Paul Doe",
      email: "PaulDoe@gmai.com",
      role: "Patient",
      action: userActions(),
    },

    {
      id: 2,
      UserId: "02",
      fullname: "John Doe",
      email: "PaulDoe@gmai.com",
      role: "Patient",
    },

    {
      id: 3,
      UserId: "03",
      fullname: "Ahmed",
      email: "PaulDoe@gmai.com",
      role: "Patient",
    },
    {
      id: 4,
      UserId: "04",
      fullname: "Mikaal",
      email: "PaulDoe@gmai.com",
      role: "Patient",
    },
    {
      id: 5,
      UserId: "05",
      fullname: "Michael Fiz",
      email: "PaulDoe@gmai.com",
      role: "Patient",
    },
    {
      id: 6,
      UserId: "06",
      fullname: "Laura Michel",
      email: "PaulDoe@gmai.com",
      role: "Patient",
    },
    {
      id: 7,
      UserId: "07",
      fullname: "Elijah William",
      email: "PaulDoe@gmai.com",
      role: "Patient",
    },
    {
      id: 8,
      UserId: "08",
      fullname: "Laura Michel",
      email: "PaulDoe@gmai.com",
      role: "Patient",
    },
    {
      id: 9,
      UserId: "09",
      fullname: "Elijah William",
      email: "PaulDoe@gmai.com",
      role: "Patient",
    },
  ];
  return (
    <div className="user-management ez-pay-dashboard">
      <Row>
        <Col md={12}>
          <div className="breadcrumbs">
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/dashboard">Dashboard</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <NavLink to="/ezpay/Dashboard">Ez Pay</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h2>Ez Pay Dashboard</h2>
        </Col>
        <Col md={6}>
          <div className="d-flex justify-content-start justify-content-md-end mb-3 mb-md-0">
            <div className="date-range-picker">
              <DateRange />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12} xl={4}>
          <Row>
            <Col md={6}>
              <div className="gross-sales-bg">
                <h3>Gross Sales</h3>
                <h2>$ 3000.00</h2>
                <div>
                  <EzPayStackedChart />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="gross-sales-bg">
                <h3>Net Sales</h3>
                <h2>$ 300.00</h2>
                <div>
                  <EzPayLineGraph />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="earning-bg">
                <Row>
                  <Col md={7} lg={7}>
                    <h2>Earning</h2>
                    <p>This Month</p>
                    <h2>$4055.56</h2>
                    <p>68.2% more earnings than last month.</p>
                  </Col>
                  <Col md={5} lg={5}>
                    <EzPayDonutChart />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={12} lg={12} xl={8}>
          <div className="revenue-bg">
            <Row>
              <Col md={9}>
                <h2>Revenue</h2>
              </Col>
              <Col md={3} sm={6}>
                <div className="mb-3 mb-sm-0">
                <Form.Select aria-label="Default select example">
                  <option>Current Month</option>
                  <option value="1">This Month</option>
                  <option value="2">Previous Month</option>
                </Form.Select>
                </div>
              </Col>
            </Row>
            <EzPayRevenueGraph />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={4}>
          <div className="plans-graph-container">
            <Row>
              <Col md={9} lg={6}>
                <div className="plans">Plans</div>
              </Col>
              <Col md={3} sm={6} lg={6}>
                <Form.Select aria-label="Default select example">
                  <option>This Week</option>
                  <option value="1">This Month</option>
                  <option value="2">Previous Month</option>
                </Form.Select>
              </Col>
            </Row>
            <div className="stacked-chart">
            <StackedChart />
            </div>
          </div>
        </Col>
        <Col md={12} lg={8}>
          <div className="ezpay-table">
            <div className="user-management">
              <Row>
                <Col md={12}>
                  <div className="table-container table-padding">
                    <Row>
                      <Col md={6}>
                        <div className="membership-plan">
                          EZ Pay
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="d-flex justify-content-start justify-content-md-end mt-3 mt-md-0">
                          <div>
                            <NavLink to="/EzPay/Dashboard/Records">
                            <Button className="view-membership-btn">
                              View All EzPay Records
                            </Button>
                            </NavLink>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div style={{ height: 250, width: "100%" }}>
                      <DataGrid
                        className="datagrid-table mt-3"
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[7]}
                        checkboxSelection
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EzPayDashboard;
