import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../../components/StackedChart";
import { DataGrid } from "@mui/x-data-grid";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
  MenuDots,
  FilterIcon,
  UserIcon,
} from "../../../constants/svg";
import { Assets } from "../../../constants/images";
import LineGraph from "../../../components/LineGraph";
import CareGraph from "../../../components/CareGraph";
import TotalPlansGraph from "../../../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../../../components/Statistics";
import DashboardSidebar from "../../../components/DashboardSidebar";
import ProfileBox from "../../../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../../../components/ResponsiveNavbar";
import ThemeModal from "../../../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../../../components/TopBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import AddUser from "../../../components/Modals/UserManagement/AddUser";
import EditUser from "../../../components/Modals/UserManagement/EditUser";
import DeleteRecord from "../../../components/Modals/RoleManagement/DeleteRecord";

const AllRenewalTracking = () => {
  const [show, setShow] = useState(false);
  const [showaddnew, setShowAddNew] = useState(false);
  const handleAddNew = () => setShowAddNew(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [showdelete, setShowDelete] = useState(false);

  // edit modal
  const [showeditnew, setShowEditNew] = useState(false);
  const handleEditNew = () => setShowEditNew(false);

  const userActions = (data) => {
    return (
      <DropdownButton
        align="end"
        className={"actionDropdown"}
        id="dropdown-item-button"
        title={<MenuDots />}
      >
        <Dropdown.Item as="button">Edit</Dropdown.Item>
        <Dropdown.Item as="button">Delete</Dropdown.Item>
      </DropdownButton>
    );
  };

  // const userActions = (rowData) => {
  //   return (
  //     <DropdownButton
  //       align="end"
  //       className={"actionDropdown"}
  //       id="dropdown-item-button"
  //       title={<MenuDots />}
  //     >
  //       <Dropdown.Item as="button">Edit</Dropdown.Item>
  //       <Dropdown.Item as="button">Delete</Dropdown.Item>
  //     </DropdownButton>
  //   );
  // };
    
  const columns = [
    {
      field: "UserId",
      headerName: "User ID",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "membername",
      headerName: "Member Name",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "memberid",
      headerName: "Member ID",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: "Status",
      width: 120,
      id: "action",
      name: "Action",
      renderCell: (cellValues) => {
        return (
          <div className="table-status">
            Active
        </div>
        );
      },    
},
    {
      field: "renewalamount",
      headerName: "Renewal Amount",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "autorenewal",
      headerName: "Auto Renewal",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "renewaldate",
      headerName: "Renewal Date",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "pay",
      headerName: "Pay",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
  ];

  const rows = [
    {
      id: 1,
      UserId: "01",
      membername: "Paul Doe",
      memberid: "123",
      renewalamount: "$ 566",
      autorenewal: "On",
      renewaldate: "01/12/2022",
      pay: "abc"
    },

    {
      id: 2,
      UserId: "02",
      membername: "Paul Doe",
      memberid: "123",
      renewalamount: "$ 566",
      autorenewal: "On",
      renewaldate: "01/12/2022",
      pay: "abc"
    },

    {
      id: 3,
      UserId: "03",
      membername: "Paul Doe",
      memberid: "123",
      renewalamount: "$ 566",
      autorenewal: "On",
      renewaldate: "01/12/2022",
      pay: "abc"
    },
    {
      id: 4,
      UserId: "04",
      membername: "Paul Doe",
      memberid: "123",
      renewalamount: "$ 566",
      autorenewal: "On",
      renewaldate: "01/12/2022",
      pay: "abc"
    },
    {
      id: 5,
      UserId: "05",
      membername: "Paul Doe",
      memberid: "123",
      renewalamount: "$ 566",
      autorenewal: "On",
      renewaldate: "01/12/2022",
      pay: "abc"
    },
    {
      id: 6,
      UserId: "06",
      membername: "Paul Doe",
      memberid: "123",
      renewalamount: "$ 566",
      autorenewal: "On",
      renewaldate: "01/12/2022",
      pay: "abc"
    },
    {
      id: 7,
      UserId: "07",
      membername: "Paul Doe",
      memberid: "123",
      renewalamount: "$ 566",
      autorenewal: "On",
      renewaldate: "01/12/2022",
      pay: "abc"
    },
    {
      id: 8,
      UserId: "08",
      membername: "Paul Doe",
      memberid: "123",
      renewalamount: "$ 566",
      autorenewal: "On",
      renewaldate: "01/12/2022",
      pay: "abc"
    },
    {
      id: 9,
      UserId: "09",
      membername: "Paul Doe",
      memberid: "123",
      renewalamount: "$ 566",
      autorenewal: "On",
      renewaldate: "01/12/2022",
      pay: "abc"
    },
  ];


  return (
    <>
      <div className="user-management">
        <Row>
          <Col md={12}>
            <div className="breadcrumbs">
              <Breadcrumb>
              <Breadcrumb.Item>
                  <NavLink to="/dashboard">Dashboard</NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <NavLink to="/EzPlan/">EZ Plan Dashboard</NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <NavLink to="/EzPlan/renewal-tracking">Renewal Tracking</NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <NavLink to="/EzPlan/renewal-tracking/allrenewal-tracking">All Records</NavLink>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h2>All Renewal Tracking</h2>
          </Col>
          <Col md={6}>
            <div className="d-flex justify-content-end d-none">
              <div className="user-filter-btn">
                <DropdownButton
                  id="dropdown-basic-button"
                  className="user-filter-btn"
                  title={<FilterIcon />}
                >
                  <Dropdown.Item href="#/action-1">Email</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Role</Dropdown.Item>
                </DropdownButton>
              </div>
              <div>
                <Button
                  className="add-new-btn ms-3"
                  onClick={() => setShowAddNew(true)}
                >
                  + Add New
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="table-container">
            <div style={{ height: 650, width: "100%" }}>
                <DataGrid
                  className="datagrid-table"
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[7]}
                  checkboxSelection
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>

      {/* USER MANAGEMENT MODAL STARTS HERE */}

      {/* ADD USER MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Add User"}
          content={<AddUser />}
          // footer={<LogoutModalButtons />}
          show={showaddnew}
          onHide={() => setShowAddNew(false)}
        />
      </div>
      {/* END OF ADD USER MODAL */}

      {/* Edit USER MODAL */}
      <ThemeModal
        className="ezfi-modal"
        title={"Edit User"}
        content={<EditUser />}
        // footer={<LogoutModalButtons />}
        show={showeditnew}
        onHide={() => setShowEditNew(false)}
      />
      {/* END OF Edit USER MODAL */}

      {/* EDIT USER MODAL */}
      {/* END OF EDIT USER MODAL */}

      {/* Delete User MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Alert"}
          content={<DeleteRecord />}
          show={showdelete}
          onHide={() => setShowDelete(false)}
        />
      </div>
      {/* END OF Delete User MODAL */}
      {/* USER MANAGEMENT MODAL ENDS HERE */}
    </>
  );
};

export default AllRenewalTracking;
