import { React, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../../components/StackedChart";
import { DataGrid } from "@mui/x-data-grid";
import MultiRangeSlider from "multi-range-slider-react";
import {
  VALIDATIONS,
  VALIDATIONS_TEXT,
} from "../../../constants/app-constants";
import { useForm } from "react-hook-form";

const AddAppointment = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);
  return (
    <>
      <div className="user-modal  validation-margin">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Appointment Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Appointment 1"
              {...register("appointment", {
                minLength: {
                  value: 2,
                  message: "Name is very short",
                },
                maxLength: {
                  value: VALIDATIONS.ROLE_NAME,
                  message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                },
                required: {
                  value: true,
                  message: "Appointment Name is required",
                },
              })}
            />
            {errors.appointment && <span className="validations">{errors.appointment.message}</span>}
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              {...register("description", {
                minLength: {
                  value: 2,
                  message: "Name is very short",
                },
                maxLength: {
                  value: VALIDATIONS.ROLE_NAME,
                  message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                },
                required: {
                  value: true,
                  message: "Description is required",
                },
              })}
            />
            {errors.description && <span className="validations">{errors.description.message}</span>}
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>Total Time (Mins)</Form.Label>
            <Form.Control
              type="text"
              placeholder="60 Minutes"
              {...register("time", {
                maxLength: {
                  value: VALIDATIONS.ROLE_NAME,
                  message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                },
                required: {
                  value: true,
                  message: "Total Time is required",
                },
              })}
            />
            {errors.time && <span className="validations">{errors.time.message}</span>}
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>Monthly Interval</Form.Label>
            <Form.Control
              type="text"
              placeholder="2 Months"
              {...register("months", {
                minLength: {
                  value: 2,
                  message: "Name is very short",
                },
                maxLength: {
                  value: VALIDATIONS.ROLE_NAME,
                  message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                },
                required: {
                  value: true,
                  message: "Monthly Interval is required",
                },
              })}
            />
            {errors.months && <span className="validations">{errors.months.message}</span>}
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>CDT Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="CD21344"
              {...register("cdt", {
                minLength: {
                  value: 2,
                  message: "Name is very short",
                },
                maxLength: {
                  value: VALIDATIONS.ROLE_NAME,
                  message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                },
                required: {
                  value: true,
                  message: "CDT Code is required",
                },
              })}
            />
            {errors.cdt && <span className="validations">{errors.cdt.message}</span>}
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              {...register("desc", {
                maxLength: {
                  value: VALIDATIONS.ROLE_NAME,
                  message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                },
                required: {
                  value: true,
                  message: "Description is required",
                },
              })}
            />
            {errors.desc && <span className="validations">{errors.desc.message}</span>}
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>CDT Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="CD21344"
              {...register("cdt", {
                minLength: {
                  value: 2,
                  message: "Name is very short",
                },
                maxLength: {
                  value: VALIDATIONS.ROLE_NAME,
                  message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                },
                required: {
                  value: true,
                  message: "CDT Code is required",
                },
              })}
            />
            {errors.cdt && <span className="validations">{errors.cdt.message}</span>}
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              {...register("desc", {
                maxLength: {
                  value: VALIDATIONS.ROLE_NAME,
                  message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                },
                required: {
                  value: true,
                  message: "Description is required",
                },
              })}
            />
            {errors.desc && <span className="validations">{errors.desc.message}</span>}
          </Form.Group>

          <div className="text-end">
            <Button
              type="Submit"
              className="modal_submit_btn"
              variant="primary"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddAppointment;
