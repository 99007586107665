import { React, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../../components/StackedChart";
import { DataGrid } from "@mui/x-data-grid";
import MultiRangeSlider from "multi-range-slider-react";
import {
  VALIDATIONS,
  VALIDATIONS_TEXT,
} from "../../../constants/app-constants";
import { useForm } from "react-hook-form";

const AddFeeSchedule = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);
  return (
    <>
      <div className="user-modal  validation-margin">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>CDT Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter CDT Code"
              {...register("cdtcode", {
                minLength: {
                  value: 2,
                  message: "Name is very short",
                },
                maxLength: {
                  value: VALIDATIONS.ROLE_NAME,
                  message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                },
                required: {
                  value: true,
                  message: "CDT Code is required",
                },
              })}
            />
            {errors.cdtcode && <span className="validations">{errors.cdtcode.message}</span>}
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>CDT Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter CDT Name"
              {...register("cdtname", {
                minLength: {
                  value: 2,
                  message: "Name is very short",
                },
                maxLength: {
                  value: VALIDATIONS.ROLE_NAME,
                  message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                },
                required: {
                  value: true,
                  message: "CDT Name is required",
                },
              })}
            />
            {errors.cdtname && <span className="validations">{errors.cdtname.message}</span>}
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              {...register("description", {
                minLength: {
                  value: 2,
                  message: "Name is very short",
                },
                maxLength: {
                  value: VALIDATIONS.ROLE_NAME,
                  message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                },
                required: {
                  value: true,
                  message: "Description is required",
                },
              })}
            />
            {errors.description && <span className="validations">{errors.description.message}</span>}
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>Category</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Category"
              {...register("category", {
                maxLength: {
                  value: VALIDATIONS.ROLE_NAME,
                  message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                },
                required: {
                  value: true,
                  message: "Category is required",
                },
              })}
            />
            {errors.category && <span className="validations">{errors.category.message}</span>}
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>UCR Fee</Form.Label>
            <Form.Control
              type="text"
              placeholder="2 Months"
              {...register("ucrfee", {
                minLength: {
                  value: 2,
                  message: "Name is very short",
                },
                maxLength: {
                  value: VALIDATIONS.ROLE_NAME,
                  message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                },
                required: {
                  value: true,
                  message: "UCR Fee is required",
                },
              })}
            />
            {errors.ucrfee && <span className="validations">{errors.ucrfee.message}</span>}
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>Additional Discount Eligibility</Form.Label>
            <Form.Control
              type="text"
              placeholder="$"
              {...register("discount", {
                minLength: {
                  value: 2,
                  message: "Name is very short",
                },
                maxLength: {
                  value: VALIDATIONS.ROLE_NAME,
                  message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                },
                required: {
                  value: true,
                  message: "Additional Discount Eligibility is required",
                },
              })}
            />
            {errors.discount && <span className="validations">{errors.discount.message}</span>}
          </Form.Group>

          <div className="text-end">
            <Button
              type="Submit"
              className="modal_submit_btn"
              variant="primary"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddFeeSchedule;
