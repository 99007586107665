import { React, useState } from "react";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
} from "../constants/svg";
import { Button, Dropdown, DropdownButton, ButtonGroup } from "react-bootstrap";
import { Assets } from "../constants/images";
import { Link, NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ThemeModal from "../components/ThemeModal";
import LogoutModal from "./Modals/Logout/LogoutModal.jsx"

const DashboardSidebar = () => {
  const [show, setShow] = useState(false);
  const [showlogout, setShowlogout] = useState(false);
  const handleClose = () => setShowlogout(false);
  const handleShow = () => setShow(true);

  const [notifShow, setNotifShow] = useState(false);
  const [shownotif, setShowNotif] = useState(false);
  const handleNotification = () => setShowNotif(false);
  const handleNotifShow = () => setNotifShow(true);
  const handleNotifClose = () => setNotifShow(false);

  function LogoutModalButtons() {
    return (
      <>
        <NavLink to="/">
          <Button
            className="modal_blue_btn"
            variant="primary"
            onClick={handleClose}
          >
            Logout
          </Button>
        </NavLink>
        <Button
          className="modal_gray_btn"
          variant="secondary"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </>
    );
  }

  return (
    <div className="dashboard-sidebar">
      <NavLink to="/dashboard">
        <img src={Assets.Logo} className="img-fluid ezfi-logo-dashboard ps-2" />
      </NavLink>

      <ul>
        <li className="">
          <NavLink to="/dashboard">
            <div className="nav-bg">
              <span>
                <DashboardIcon />
              </span>
              <span className="ps-3">Dashboard</span>
            </div>
          </NavLink>
        </li>
        <li className="mt-3">
          <NavLink to="/usermanagement">
            <div className="nav-bg">
              <span>
                <User />
              </span>
              <span className="ps-3">User</span>
            </div>
          </NavLink>
        </li>
        <li className="mt-3">
          <NavLink to="/rolemanagement">
            <div className="nav-bg">
              <span>
                <Role />
              </span>
              <span className="ps-3">Role</span>
            </div>
          </NavLink>
        </li>
        <li className="mt-3">
          <NavLink to="/marketing">
            <div className="nav-bg">
              <span>
                <Marketing />
              </span>
              <span className="ps-3">Marketing</span>
            </div>
          </NavLink>
        </li>
        <li className="mt-3">
          <NavLink to="/paymentplan">
            <div className="nav-bg">
              <span>
                <Payment />
              </span>
              <span className="ps-3">Payment Plan</span>
            </div>
          </NavLink>
        </li>
        <li className="mt-3">
          <NavLink to="/Financing">
            <div className="nav-bg">
              <span>
                <Financing />
              </span>
              <span className="ps-3">Financing Plan</span>
            </div>
          </NavLink>
        </li>
        <li className="mt-3">
          <NavLink to="/appointment">
            <div className="nav-bg">
              <span>
                <Appointment />
              </span>
              <span className="ps-3">Appointment</span>
            </div>
          </NavLink>
        </li>
        <li className="mt-3">
          <NavLink to="/ezpay/Dashboard">
            <div className="nav-bg">
              <span>
                <Ezpay />
              </span>
              <span className="ps-3">EZ Pay</span>
            </div>
          </NavLink>
        </li>
        <li className="mt-3">
        <NavLink to="/EzPlan/">
                <div className="nav-bg has-children">
                  <span>
                    <Ezplan />
                  </span>
                  <span className="ps-3">EZ Plan</span>
                  <ul className="nav-child-list">
                    <li className="nav-child-item">
                      <NavLink to="/EzPlan/renewal-tracking">
                      Renewal Tracking
                      </NavLink>
                    </li>
                    <li className="nav-child-item p_28">
                      <NavLink to="/EzPlan/CategoryDiscount">
                      Category Discounts
                      </NavLink>
                    </li>
                    <li className="nav-child-item p_28">
                      <NavLink to="/EzPlan/FeeSchedule">
                      Fee Schedule
                      </NavLink>
                    </li>
                    <li className="nav-child-item p_28">
                      <NavLink to="/EzPlan/featured-plans">
                      Membership Plan
                      </NavLink>
                    </li>
                    <li className="nav-child-item p_28">
                      <NavLink to="/EzPlan/patients">
                      Patients
                      </NavLink>
                    </li>
                    <li className="nav-child-item p_28">
                      <NavLink to="/EzPlan/CorporateClients">
                      Corporate Clients
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </NavLink>
        </li>
        {/* <li className="mt-3  ">
        <DropdownButton
        className="mt-3"
          id={`dropdown-button-drop-end`}
          alignRight
                    title={
              <NavLink to="/EzPlan/">
                <div className="nav-bg">
                  <span>
                    <Ezplan />
                  </span>
                  <span className="ps-3">EZ Plan</span>
                </div>
              </NavLink>

          }
        >
          <Dropdown.Item>Edit</Dropdown.Item>
          <Dropdown.Item>Deactivate</Dropdown.Item>
        </DropdownButton>
        </li> */}


        {/* <div className="mb-2">
        {['end'].map((direction) => (
          <DropdownButton
            as={ButtonGroup}
            key={direction}
            id={`dropdown-button-drop-${direction}`}
            drop={direction}
            title={        <NavLink to="/EzPlan/patients">
            <span>
              <Ezplan />
            </span>
            <span className="ps-3">EZ Plan</span>
            </NavLink>}
          >
            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
            <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
            <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
          </DropdownButton>
        ))}
      </div> */}
        <li className="mt-3">
          <NavLink to="/administration">
            <div className="nav-bg">
              <span>
                <Administrator />
              </span>
              <span className="ps-3">Administration</span>
            </div>
          </NavLink>
        </li>
        <li className="mt-3">
          <NavLink to="/content/termsandcondition">
            <div className="nav-bg">
              <span>
                <Content />
              </span>
              <span className="ps-3">Content</span>
            </div>
          </NavLink>
        </li>
        <li className="mt-3" onClick={handleNotifShow}>
          <NavLink className=" non-active" to="#">
            <div className="non-active">
              <span>
                <Notification />
              </span>
              <span className="ps-3">Notification</span>
            </div>
          </NavLink>
        </li>
        <li className="mt-3" onClick={() => setShowlogout(true)}>
          <div className="non-active">
            <span>
              <Logout />
            </span>
            <span className="ps-3">Logout</span>
          </div>
        </li>
      </ul>
      {/* Logout MODAL STARTS HERE */}
      <ThemeModal
        className="ezfi-modal"
        title={"Alert"}
        content={<LogoutModal/>}
        show={showlogout}
        onHide={() => setShowlogout(false)}
      />
      {/* Logout MODAL ENDS HERE */}


      {/* Notification Modal Starts Here */}
      <Modal
        className="notification-modal"
        show={notifShow}
        onHide={handleNotifClose}
        size="md"
        aria-labelledby="contained-modal-title-center"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="header d-flex w-100 align-items-center justify-content-between ">
              <h6 className="p-0 m-0 modal-heading">
                Notifications <span>(2)</span>
              </h6>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="notification-container">
            <div className="notification-card mb-2 border-bottom pb-2">
              <div
                className={`notification-box  d-flex align-items-center p-3`}
              >
                <div>
                  <img src={Assets.AvatarOne} alt="Avatar" />
                </div>
                <div className="ms-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="d-block">
                      <b>Ashley Edward</b>
                    </span>
                  </div>
                  <p className="p-0 m-0">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sequi, aliquam aliquid!
                  </p>
                  <small className="time-small">23m ago</small>
                </div>
              </div>
              <div
                className={`notification-box  d-flex align-items-center p-3`}
              >
                <div>
                  <img src={Assets.AvatarTwo} alt="Avatar" />
                </div>
                <div className="ms-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="d-block">
                      <b>Ashley Edward</b>
                    </span>
                  </div>
                  <p className="p-0 m-0">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sequi, aliquam aliquid!
                  </p>
                  <small className="time-small">23m ago</small>
                </div>
              </div>
              <div
                className={`notification-box  d-flex align-items-center p-3`}
              >
                <div>
                  <img src={Assets.AvatarThree} alt="Avatar" />
                </div>
                <div className="ms-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="d-block">
                      <b>Ashley Edward</b>
                    </span>
                  </div>
                  <p className="p-0 m-0">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sequi, aliquam aliquid!
                  </p>
                  <small className="time-small">23m ago</small>
                </div>
              </div>
              <div
                className={`notification-box  d-flex align-items-center p-3`}
              >
                <div>
                  <img src={Assets.AvatarOne} alt="Avatar" />
                </div>
                <div className="ms-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="d-block">
                      <b>Ashley Edward</b>
                    </span>
                  </div>
                  <p className="p-0 m-0">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sequi, aliquam aliquid!
                  </p>
                  <small className="time-small">23m ago</small>
                </div>
              </div>
              <div
                className={`notification-box  d-flex align-items-center p-3`}
              >
                <div>
                  <img src={Assets.AvatarThree} alt="Avatar" />
                </div>
                <div className="ms-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="d-block">
                      <b>Ashley Edward</b>
                    </span>
                  </div>
                  <p className="p-0 m-0">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sequi, aliquam aliquid!
                  </p>
                  <small className="time-small">23m ago</small>
                </div>
              </div>
              <div
                className={`notification-box  d-flex align-items-center p-3`}
              >
                <div>
                  <img src={Assets.AvatarTwo} alt="Avatar" />
                </div>
                <div className="ms-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="d-block">
                      <b>Ashley Edward</b>
                    </span>
                  </div>
                  <p className="p-0 m-0">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Sequi, aliquam aliquid!
                  </p>
                  <small className="time-small">23m ago</small>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Notification Modal Ends Here */}
    </div>
  );
};

export default DashboardSidebar;
