const CardData = [
  {
    id:1,
    title: "Orthodontic Treatment",
    description:
      "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur,",
    checkone: "Payment Source:",
    checkoneans: "Third Party",
    checktwo: "Amount Source:",
    checktwoans: "Treatment Plan",
    checkthree: "Payment Mode:",
    checkthreeans: "Automatic",
    checkfour: "Addition Discount Eligibility:",
    checkfourans: "10%",
  },
  {
    id:2,
    title: "Dental Plan",
    description:
      "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur,",
    checkone: "Payment Source:",
    checkoneans: "Third Party",
    checktwo: "Amount Source:",
    checktwoans: "Treatment Plan",
    checkthree: "Payment Mode:",
    checkthreeans: "Automatic",
    checkfour: "Addition Discount Eligibility:",
    checkfourans: "10%",
  },
  {
    id:3,
    title: "Dental Plan",
    description:
      "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur,",
    checkone: "Payment Source:",
    checkoneans: "Third Party",
    checktwo: "Amount Source:",
    checktwoans: "Treatment Plan",
    checkthree: "Payment Mode:",
    checkthreeans: "Automatic",
    checkfour: "Addition Discount Eligibility:",
    checkfourans: "10%",
  },
  {
    id:4,
    title: "Dental Plan",
    description:
      "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur,",
    checkone: "Payment Source:",
    checkoneans: "Third Party",
    checktwo: "Amount Source:",
    checktwoans: "Treatment Plan",
    checkthree: "Payment Mode:",
    checkthreeans: "Automatic",
    checkfour: "Addition Discount Eligibility:",
    checkfourans: "10%",
  },
  {
    id:5,
    title: "Dental Plan",
    description:
      "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur,",
    checkone: "Payment Source:",
    checkoneans: "Third Party",
    checktwo: "Amount Source:",
    checktwoans: "Treatment Plan",
    checkthree: "Payment Mode:",
    checkthreeans: "Automatic",
    checkfour: "Addition Discount Eligibility:",
    checkfourans: "10%",
  },
  {
    id:5,
    title: "Orthodontic Treatment",
    description:
      "lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur,",
    checkone: "Payment Source:",
    checkoneans: "Third Party",
    checktwo: "Amount Source:",
    checktwoans: "Treatment Plan",
    checkthree: "Payment Mode:",
    checkthreeans: "Automatic",
    checkfour: "Addition Discount Eligibility:",
    checkfourans: "10%",
  },
];

export default CardData;
