import React from "react";
import { ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";


const TotalPlansGraph = () => {
  const data = {
    labels: ["Heart", "Blood", "Bones", "ENT", "Dental", "Eye"],
    datasets: [
      {
        data: [250,250, 300, 500, 350, 200],
        backgroundColor: ["#8FAEFC","#5FBF24", "#39B6D2", "#3B7AFE","#139CBB","#A284E8"],
        hoverBackgroundColor: ["#8FAEFC","#5FBF24", "#39B6D2", "#3B7AFE","#139CBB","#A284E8"],
        borderWidth: 2
      }
    ]
  };
  return (
    <Doughnut data={data}  />
    )
}

export default TotalPlansGraph