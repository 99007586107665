import { React, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Eye, EyeClose, Tick } from "../../../constants/svg";
import { Assets } from "../../../constants/images";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
// import {FaEye, FaEyeSlash} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import {
  VALIDATIONS,
  VALIDATIONS_TEXT,
} from "../../../constants/app-constants";

const EditFinancingPlan = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);
  return (
    <div className="user-modal  validation-margin">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Payment Plan</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter payemnt plan"
            {...register("payment", {
              maxLength: {
                value: VALIDATIONS.ROLE_NAME,
                message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
              },
              required: {
                value: true,
                message: "Payment Plan is requried",
              },
            })}
          />
          {errors.payment && <span className="validations">{errors.payment.message}</span>}
        </Form.Group>

        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            {...register("description", {
              maxLength: {
                value: VALIDATIONS.ROLE_NAME,
                message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
              },
              required: {
                value: true,
                message: "Description is requried",
              },
            })}
          />
          {errors.description && <span className="validations">{errors.description.message}</span>}
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Payment Source</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter payemnt source"
            {...register("paymentsource", {
              maxLength: {
                value: VALIDATIONS.ROLE_NAME,
                message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
              },
              required: {
                value: true,
                message: "Payment Source is requried",
              },
            })}
          />
          {errors.paymentsource && <span className="validations">{errors.paymentsource.message}</span>}
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Amount Source</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter amount source"
            {...register("amount", {
              maxLength: {
                value: VALIDATIONS.ROLE_NAME,
                message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
              },
              required: {
                value: true,
                message: "Amount Source is requried",
              },
            })}
          />
          {errors.amount && <span className="validations">{errors.amount.message}</span>}
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Payment Mode</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter payment mode"
            {...register("paymentmode", {
              maxLength: {
                value: VALIDATIONS.ROLE_NAME,
                message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
              },
              required: {
                value: true,
                message: "Payment Mode is requried",
              },
            })}
          />
          {errors.paymentmode && <span className="validations">{errors.paymentmode.message}</span>}
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Additional Discount Eligibility</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter discount eligibility"
            {...register("discounteligibility", {
              maxLength: {
                value: VALIDATIONS.ROLE_NAME,
                message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
              },
              required: {
                value: true,
                message: "Discount Eligibility is requried",
              },
            })}
          />
          {errors.discounteligibility && (
            <span className="validations">{errors.discounteligibility.message}</span>
          )}
        </Form.Group>

        <div className="text-end">
          <Button className="modal_submit_btn" variant="primary" type="submit">
            Update
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditFinancingPlan;
