import React from 'react'
import { FilterIcon } from '../constants/svg'

const FilterBtn = () => {
  return (
    // <div className="d-flex">
    // <div><FilterIcon/></div>
    // <div>Filter</div>
    // </div>
    <div className='filter-btn-styling'>
        <span><FilterIcon/></span>
        <span className='filter-txt'>Filter</span>
    </div>
  )
}

export default FilterBtn