import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
// import {FaEye, FaEyeSlash} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../../../constants/app-constants"

const CreateProposal = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = data => console.log(data);

  return (
    <div className="user-modal validation-margin">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Patient Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Patient Name"
            {...register("patient", {
              maxLength: {
                value: VALIDATIONS.ROLE_NAME,
                message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
              },
              required: {
                value: true,
                message: VALIDATIONS_TEXT.PATIENT_NAME_REQUIRED,
              },
            })}
          />
         {
          errors.patient && 
          <span className="validations">{ errors.patient.message}</span>
         }
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Plan Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter plan name"
            {...register("planname", {
              maxLength: {
                value: VALIDATIONS.ROLE_NAME,
                message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
              },
              required: {
                value: true,
                message: "Plan Name is required",
              },
            })}
          />
                   {
          errors.planname && 
          <span className="validations">{ errors.planname.message}</span>
         }
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Patient Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter patient email"
            {...register("email",
            {
                maxLength: {
                    value: VALIDATIONS.EMAIL,
                    message: VALIDATIONS_TEXT.EMAIL_MAX
                },
                required: {
                    value: true,
                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                },
                pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: VALIDATIONS_TEXT.EMAIL_FORMAT
                }
            })
        }
          />
                   {
          errors.email && 
          <span className="validations">{ errors.email.message}</span>
         }
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Financing Plan</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter financing plan"
            {...register("finance", {
              maxLength: {
                value: VALIDATIONS.ROLE_NAME,
                message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
              },
              required: {
                value: true,
                message: "Finance Plan is required",
              },
            })}
          />
                   {
          errors.finance && 
          <span className="validations">{ errors.finance.message}</span>
         }
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Payment Plan</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter payment plan"
            {...register("payment", {
              maxLength: {
                value: VALIDATIONS.ROLE_NAME,
                message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
              },
              required: {
                value: true,
                message: "Payment Plan is required",
              },
            })}
          />
                   {
          errors.payment && 
          <span className="validations">{ errors.payment.message}</span>
         }
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>No of Appointments</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter no of appointments"
            {...register("appointment", {
              maxLength: {
                value: VALIDATIONS.ROLE_NAME,
                message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
              },
              required: {
                value: true,
                message: "Number of appointments is required",
              },
            })}
          />
                   {
          errors.appointment && 
          <span className="validations">{ errors.appointment.message}</span>
         }
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Duration</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter duration"
            {...register("duration", {
              maxLength: {
                value: VALIDATIONS.ROLE_NAME,
                message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
              },
              required: {
                value: true,
                message: "Duration is required",
              },
            })}
          />
                   {
          errors.duration && 
          <span className="validations">{ errors.duration.message}</span>
         }
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Interest Rate</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter interest rate"
            {...register("interest", {
              maxLength: {
                value: VALIDATIONS.ROLE_NAME,
                message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
              },
              required: {
                value: true,
                message: "Interest Rate is required",
              },
            })}
          />
                   {
          errors.interest && 
          <span className="validations">{ errors.interest.message}</span>
         }
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Total Fees</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter total fees"
            {...register("fees", {
              maxLength: {
                value: VALIDATIONS.ROLE_NAME,
                message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
              },
              required: {
                value: true,
                message: "Total fees is required",
              },
            })}
          />
                   {
          errors.fees && 
          <span className="validations">{ errors.fees.message}</span>
         }
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Status</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter status"
            {...register("status", {
              maxLength: {
                value: VALIDATIONS.ROLE_NAME,
                message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
              },
              required: {
                value: true,
                message: "Status is required",
              },
            })}
          />
                   {
          errors.status && 
          <span className="validations">{ errors.status.message}</span>
         }
        </Form.Group>

        <div className="text-end">
          <Button className="modal_submit_btn" variant="primary" type="submit">
            Add
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CreateProposal;
