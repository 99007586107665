import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../components/StackedChart";
import { DataGrid } from "@mui/x-data-grid";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
  MenuDots,
  ThreeDots,
  GreenTick,
} from "../../constants/svg";
import { Assets } from "../../constants/images";
import LineGraph from "../../components/LineGraph";
import CareGraph from "../../components/CareGraph";
import TotalPlansGraph from "../../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../../components/Statistics";
import DashboardSidebar from "../../components/DashboardSidebar";
import ProfileBox from "../../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import ThemeModal from "../../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../../components/TopBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PaymentCard from "../../components/PaymentCard";
import CardData from "../PaymentPlan/CardData";
import FinancingCard from "../../components/FinancingCard";
import FinancingCardData from "./FinancingCardData";
import AddFinancingPlan from "../../components/Modals/FinancingPlan/AddFinancingPlan";
const FinancingPage = () => {
  const [financingdata, setFinancingData] = useState(FinancingCardData);
  const [showfinancingplan, setShowFinancingPlan] = useState(false);

  console.log(financingdata);
  return (
    <div className="user-management">
      <Row>
        <Col md={12}>
          <div className="breadcrumbs">
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/dashboard">Dashboard</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <NavLink to="/Financing">Financing Plan</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h2>Financing Plan</h2>
        </Col>
        <Col md={6}>
          <div className="d-flex justify-content-start justify-content-md-end">
            <div>
              <Button className="filter-btn d-none">Filter</Button>
            </div>
            <div>
              <Button
                onClick={() => setShowFinancingPlan(true)}
                className="add-new-btn "
              >
                + Add New
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <FinancingCard financingdata={financingdata} />
      </Row>

      {/*Financing Plan MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Add Financing Plan"}
          content={<AddFinancingPlan />}
          show={showfinancingplan}
          onHide={() => setShowFinancingPlan(false)}
        />
      </div>
      {/* END OF Financing Plan MODAL*/}
    </div>
  );
};

export default FinancingPage;
 