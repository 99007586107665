import { React, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Assets } from "../../constants/images";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
// import {FaEye, FaEyeSlash} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MainLogo } from "../../constants/svg";
import WomanImg from "../../assets/images/onboarding-img.png";
import Signin from "../components/NewComponents/Signin"

const NewComponent = (props) => {
  return (
    <>
    <ToastContainer/>
      <section
        className="bg_color_img wdth_1366"
        style={{ backgroundImage: `url(${WomanImg})` }}
      >
        <Container className="h-100">
          <Row className="h-100">
            <Col md={8} xl={4} sm={8} lg={5} className="mt-auto mb-auto responsive_margin">
              <div className="logo">
                <Link to="/">
                  <MainLogo />
                </Link>
              </div>
              <div className="main_form">
                <h1>{props.heading}</h1>
                <p>{props.description}</p>
              </div>
              <div>
                {props.children}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default NewComponent;
