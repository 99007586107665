import React from "react";
import {Col,Row} from "react-bootstrap"

const FeaturedPlanCards = () => {
    const items=['card 1','card 2','card 3','card 4'];
  return (
    <>
         {items.map((item,index)=>{
         return (
            <Col md={6}>
            <div className="featured-plan-cards">
              <Row>
                <Col md={6}>
                  <h3>Adult Hygiene</h3>
                  <p>Retail</p>
                </Col>
                <Col md={6}>
                  <div className="patient-saving">
                    <span className="patient-saving-text">Patient Saving: </span>
                    <span className="hundred">$100</span>
                  </div>
                </Col>
              </Row>
              <hr />
              <div className="d-flex">
                <div>
                  <span className="card-data-text">Total Appointments :</span>
                  <span className="card-data-ans">12</span>
                </div>
                <div className="ms-3">
                  <span className="card-data-text">Duration :</span>
                  <span className="card-data-ans">12 Months</span>
                </div>
              </div>
              <div className="d-flex mt_15">
                <div>
                  <span className="card-data-text">Total Fees :</span>
                  <span className="card-data-ans">$1020</span>
                </div>
                <div className="ms-3">
                  <span className="card-data-text">Plan Fees :</span>
                  <span className="card-data-ans">$300</span>
                </div>
              </div>
            </div>
          </Col>
         )
     })}

    </>
  );
};

export default FeaturedPlanCards;
