import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../components/StackedChart";
import { DataGrid } from "@mui/x-data-grid";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
  MenuDots,
} from "../../constants/svg";
import { Assets } from "../../constants/images";
import LineGraph from "../../components/LineGraph";
import CareGraph from "../../components/CareGraph";
import TotalPlansGraph from "../../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../../components/Statistics";
import DashboardSidebar from "../../components/DashboardSidebar";
import ProfileBox from "../../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import ThemeModal from "../../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../../components/TopBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import CreateProposal from "../../components/Modals/Marketing/CreateProposal";

const MarketingPage = () => {
  const [show, setShow] = useState(false);
  const [showaddnew, setShowAddNew] = useState(false);
  const handleAddNew = () => setShowAddNew(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [showrole, setShowRole] = useState(false);
  const [showproposal, setShowProposal] = useState(false);

  const columns = [
    {
      field: "UserId",
      headerName: "User ID",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "clientname",
      headerName: "Client Name",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "treatment",
      headerName: "Treatment",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email Address",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "financingplan",
      headerName: "Financing Plan",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "paymentplan",
      headerName: "Payment Plan",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "appointments",
      headerName: "Appointments",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
  ];

  const rows = [
    {
      id: 1,
      UserId: "01",
      clientname: "Paul Doe",
      treatment: "Dental",
      email: "PaulDoe@gmai.com",
      financingplan: "abc",
      paymentplan: "abc",
      appointments: "3",
      duration: "3",
    },
    {
      id: 2,
      UserId: "02",
      clientname: "Paul Doe",
      treatment: "Dental",
      email: "PaulDoe@gmai.com",
      financingplan: "abc",
      paymentplan: "abc",
      appointments: "3",
      duration: "3",
    },

    {
      id: 3,
      UserId: "03",
      clientname: "Paul Doe",
      treatment: "Dental",
      email: "PaulDoe@gmai.com",
      financingplan: "abc",
      paymentplan: "abc",
      appointments: "3",
      duration: "3",
    },
    {
      id: 4,
      UserId: "04",
      clientname: "Paul Doe",
      treatment: "Dental",
      email: "PaulDoe@gmai.com",
      financingplan: "abc",
      paymentplan: "abc",
      appointments: "3",
      duration: "3",
    },
    {
      id: 5,
      UserId: "05",
      clientname: "Paul Doe",
      treatment: "Dental",
      email: "PaulDoe@gmai.com",
      financingplan: "abc",
      paymentplan: "abc",
      appointments: "3",
      duration: "3",
    },
    {
      id: 6,
      UserId: "06",
      clientname: "Paul Doe",
      treatment: "Dental",
      email: "PaulDoe@gmai.com",
      financingplan: "abc",
      paymentplan: "abc",
      appointments: "3",
      duration: "3",
    },
    {
      id: 7,
      UserId: "07",
      clientname: "Paul Doe",
      treatment: "Dental",
      email: "PaulDoe@gmai.com",
      financingplan: "abc",
      paymentplan: "abc",
      appointments: "3",
      duration: "3",
    },
    {
      id: 8,
      UserId: "08",
      clientname: "Paul Doe",
      treatment: "Dental",
      email: "PaulDoe@gmai.com",
      financingplan: "abc",
      paymentplan: "abc",
      appointments: "3",
      duration: "3",
    },
    {
      id: 9,
      UserId: "09",
      clientname: "Paul Doe",
      treatment: "Dental",
      email: "PaulDoe@gmai.com",
      financingplan: "abc",
      paymentplan: "abc",
      appointments: "3",
      duration: "3",
    },
  ];

  return (
    <div className="user-management">
      <Row>
        <Col md={12}>
          <div className="breadcrumbs">
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/dashboard">Dashboard</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <NavLink to="/marketing">Marketing</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={8}>
          <h2>Marketing</h2>
        </Col>
        <Col md={6} sm={4}>
          <div className="d-flex justify-content-start justify-content-sm-end">
            <div>
              <Button className="filter-btn d-none">Filter</Button>
            </div>
            <div>
              <Button
                onClick={() => setShowProposal(true)}
                className="add-new-btn"
              >
                + Create Proposal
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="table-container">
            <div style={{ height: 650, width: "100%" }}>
              <DataGrid
                className="datagrid-table"
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[7]}
                checkboxSelection
              />
            </div>
          </div>
        </Col>
      </Row>
            {/*Marketing Create Proposal MODAL */}
            <div className="pages-modals">
        <ThemeModal
        className="ezfi-modal"
          title={"Create Proposal"}
          content={<CreateProposal/>}
          show={showproposal}
          onHide={() => setShowProposal(false)}
        />
      </div>
      {/* END OF Marketing Create Proposal MODAL*/}
    </div>
  );
};

export default MarketingPage;
