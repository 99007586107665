import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import {
    DashboardIcon,
    User,
    Role,
    Marketing,
    Payment,
    Financing,
    Appointment,
    Ezpay,
    Ezplan,
    Administrator,
    Content,
    Notification,
    TotalMonth,
    TotalPlans,
    TotalFinance,
    TotalCare,
    TotalPatients,
    Logout,
    SearchIcon,
    DashboardNotification,
    Profilearrow
  } from "../constants/svg";

const Statistics = () => {
  return (
    <>
                {/* Statistics Section Starts Here */}
                <div className="statistics-section all-section-top-gap">
              <div className="stats">Statistics</div>
              <Row className="text-center">
                <Col xs={12} sm={12} md={6} lg={{ span: 2, offset: 1 }}>
                  <div className="d-flex icon-box">
                    <TotalPatients className='icon-animations' />
                    <div className="ps-3">
                      <h5>345</h5>
                      <p>
                        Total
                        Patients
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={2}>
                  <div className="d-flex icon-box">
                    <TotalCare className='icon-animations'/>
                    <div className="ps-3">
                      <h5>1233</h5>
                      <p>
                        Total
                     Care Providers
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={2}>
                  <div className="d-flex icon-box">
                    <TotalMonth className='icon-animations'/>
                    <div className="ps-3">
                      <h5>345</h5>
                      <p>
                        Total Plans This Month
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={2}>
                  <div className="d-flex icon-box">
                    <TotalMonth className='icon-animations'/>
                    <div className="ps-3">
                      <h5>345</h5>
                      <p>
                        Total Plans This Month
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={2}>
                  <div className="d-flex icon-box">
                    <TotalMonth className='icon-animations'/>
                    <div className="ps-3">
                      <h5>345</h5>
                      <p>
                        Total Plans This Month
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {/* Statistics Section Ends Here */}
            </>
  )
}

export default Statistics