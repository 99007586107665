import { React, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../../../components/StackedChart";
import { DataGrid } from "@mui/x-data-grid";
import MultiRangeSlider from "multi-range-slider-react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
  MenuDots,
  AppointmentArrow,
  EditIcon,
} from "../../../constants/svg";
import { Assets } from "../../../constants/images";
import LineGraph from "../../../components/LineGraph";
import CareGraph from "../../../components/CareGraph";
import TotalPlansGraph from "../../../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Statistics from "../../../components/Statistics";
import DashboardSidebar from "../../../components/DashboardSidebar";
import ProfileBox from "../../../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../../../components/ResponsiveNavbar";
import ThemeModal from "../../../components/ThemeModal";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import TopBar from "../../../components/TopBar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import CreateMembership from "../../../components/Modals/MembershipPlan/CreateMembership";
import AddAppointment from "../../../components/Modals/MembershipPlan/AddAppointment";
import {
  VALIDATIONS,
  VALIDATIONS_TEXT,
} from "../../../constants/app-constants";

function valuetext(number) {


  return `{value} Months`;
}

const MembershipPlan = () => {

    const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);


  const [show, setShow] = useState(false);
  const [showaddnew, setShowAddNew] = useState(false);
  const handleAddNew = () => setShowAddNew(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [showdelete, setShowDelete] = useState(false);

  // edit modal
  const [showeditnew, setShowEditNew] = useState(false);
  const handleEditNew = () => setShowEditNew(false);

  const marks = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 100,
      label: "12",
    },
  ];

  return (
    <div className="user-management">
      <Row>
        <Col md={12}>
          <div className="breadcrumbs">
            <Breadcrumb>
            <Breadcrumb.Item>
                  <NavLink to="/dashboard">Dashboard</NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <NavLink to="/EzPlan/">EZ Plan Dashboard</NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <NavLink to="/EzPlan/featured-plans">Membership Plan</NavLink>
                </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <NavLink to="/EzPlan/membership-plan">Create New Plan</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h2>Create Membership Plan</h2>
        </Col>
      </Row>
      <Row>
        <Col md={9}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="membership-bg">
            <div className="membership-container">
              <Row>
                <Col md={5}>
                    <h4 className="membership-label">Payment Mode</h4>
                    {["radio"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Corporate Plan"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                        />
                        <Form.Check
                          inline
                          label="Retail Plan"
                          name="group1"
                          type={type}
                          id={`inline-${type}-2`}
                        />
                      </div>
                    ))}

                    {/* Select Corporate Client */}
                    <h4 className="membership-label">
                      Select Corporate Client
                    </h4>
                    <Form.Select aria-label="Default select example">
                      <option value="1">KFC</option>
                      <option value="2">Toucidel</option>
                      <option value="3">KFC</option>
                    </Form.Select>

                    {/* Plan Name */}
                    <h4 className="membership-label">Plan Name</h4>
                    <Form.Group
                      className="mb-3 input-plan"
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        type="email"
                        placeholder="Enter Plan Name"
                        {...register("plan", {
                          maxLength: {
                            value: VALIDATIONS.ROLE_NAME,
                            message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                          },
                          required: {
                            value: true,
                            message: "Plan Name is requried",
                          },
                        })}
                      />
                      {errors.plan && <span>{errors.plan.message}</span>}
                    </Form.Group>

                    {/* Plan Description */}
                    <h4 className="membership-label">Description</h4>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control as="textarea" rows={3} 
                      {...register("desc", {
                        maxLength: {
                          value: VALIDATIONS.ROLE_NAME,
                          message: "Description is required",
                        },
                        required: {
                          value: true,
                          message: "Description is required",
                        },
                      })}
                    />
                                     {
                    errors.desc && 
                    <span>{ errors.desc.message}</span>
                   }
                    </Form.Group>

                    {/* Plan Terms */}
                    <h4 className="membership-label">Plan Terms</h4>
                    <Box sx={{ width: 300 }}>
                      <Slider
                        aria-label="Custom marks"
                        defaultValue={4}
                        getAriaValueText={valuetext}
                        step={1}
                        valueLabelDisplay="auto"
                        marks={marks}
                      />
                    </Box>

                    {/* Add Appointment */}
                    <Button
                      onClick={() => setShowEditNew(true)}
                      className="appointment-btn"
                    >
                      + Add Appointment
                    </Button>
                </Col>
                <Col md={12}>
                  {/* Appointment 1 Starts Here*/}
                  <div className="appointment-container">
                    <Row>
                      <Col md={8}>
                        <div className="d-flex pt-2">
                          <AppointmentArrow />
                          <h3 className="ms-2">Appointment 1</h3>
                        </div>
                        <Row>
                          <Col md={8}>
                            <div className="week-title">Week 1</div>
                            <div className="week-description">
                              Lorem ipsum dolor sit amet consectetur,
                              adipisicing elit.
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Row>
                              <Col md={4}>
                                <Form>
                                  {/* CDT Code */}
                                  <h4 className="membership-label">CDT Code</h4>
                                  <Form.Group
                                    className="mb-3 input-plan"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Control
                                      type="email"
                                      placeholder="CD21344"
                                      {...register("cdt", {
                                        maxLength: {
                                          value: VALIDATIONS.ROLE_NAME,
                                          message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                                        },
                                        required: {
                                          value: true,
                                          message: "CDT Code is required",
                                        },
                                      })}
                                    />
                                                     {
                                    errors.cdt && 
                                    <span>{ errors.cdt.message}</span>
                                   }
                                  </Form.Group>
                                </Form>
                              </Col>
                              <Col md={8}>
                                {/* CDT Description */}
                                <h4 className="membership-label">
                                  Description
                                </h4>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Control as="textarea" rows={3}
                                  {...register("cdtdesc", {
                      maxLength: {
                        value: VALIDATIONS.ROLE_NAME,
                        message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                      },
                      required: {
                        value: true,
                        message: "Description is required",
                      },
                    })}
                  />
                                   {
                  errors.cdtdesc && 
                  <span>{ errors.cdtdesc.message}</span>
                 }
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <Form>
                                  {/* CDT Code */}
                                  <h4 className="membership-label">CDT Code</h4>
                                  <Form.Group
                                    className="mb-3 input-plan"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Control
                                      type="email"
                                      placeholder="CD21344"
                                      {...register("cdtcodetwo", {
                                        maxLength: {
                                          value: VALIDATIONS.ROLE_NAME,
                                          message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                                        },
                                        required: {
                                          value: true,
                                          message: "CDT Code is required",
                                        },
                                      })}
                                    />
                                                     {
                                    errors.cdtcodetwo && 
                                    <span>{ errors.cdtcodetwo.message}</span>
                                   }
                                  </Form.Group>
                                </Form>
                              </Col>
                              <Col md={8}>
                                {/* CDT Description */}
                                <h4 className="membership-label">
                                  Description
                                </h4>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Control as="textarea" rows={3}
                                                                    {...register("cdtdesctwo", {
                                                                      maxLength: {
                                                                        value: VALIDATIONS.ROLE_NAME,
                                                                        message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                                                                      },
                                                                      required: {
                                                                        value: true,
                                                                        message: "Description is required",
                                                                      },
                                                                    })}
                                                                  />
                                                                                   {
                                                                  errors.cdtdesctwo && 
                                                                  <span>{ errors.cdtdesctwo.message}</span>
                                                                 }
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={4}>
                        <div
                          onClick={() => setShowAddNew(true)}
                          className="text-end edit-icon"
                        >
                          <EditIcon className="edit-icon" />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {/* Appointment 1 Section Ends Here */}
                </Col>
                <Col md={12}>
                  <Row>
                    <Col md={6}>
                      {/* Reason */}
                      <h4 className="membership-label">Reason</h4>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control as="textarea" rows={3}
                        {...register("reason", {
                          maxLength: {
                            value: VALIDATIONS.ROLE_NAME,
                            message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                          },
                          required: {
                            value: true,
                            message: "Reason is required",
                          },
                        })}
                      />
                                       {
                      errors.reason && 
                      <span>{ errors.reason.message}</span>
                     }
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      {/* Reason */}
                      <h4 className="membership-label">
                        Pre-op & Post-op Instructions:
                      </h4>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control as="textarea" rows={3}
                        {...register("op", {
                          maxLength: {
                            value: VALIDATIONS.ROLE_NAME,
                            message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                          },
                          required: {
                            value: true,
                            message: "Pre-op & Post-op instructions required",
                          },
                        })}
                      />
                                       {
                      errors.op && 
                      <span>{ errors.op.message}</span>
                     }
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      {/* Reason */}
                      <h4 className="membership-label">
                        What to expect the day of procedure:
                      </h4>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control as="textarea" rows={3}
                        {...register("expect", {
                          maxLength: {
                            value: VALIDATIONS.ROLE_NAME,
                            message: VALIDATIONS_TEXT.ROLE_NAME_MAX,
                          },
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                        })}
                      />
                                       {
                      errors.expect && 
                      <span>{ errors.expect.message}</span>
                     }
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="text-end">
                    <Button
                      type="submit"
                      className="create-plan-btn"
                    >
                      Create Plan
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          </Form>
        </Col>

        <Col md={3}>
          <div className="fees-container">
            <div className="fee-padding">
              <Row>
                <Col md={6}>
                  <h4 className="membership-label">UCR Fee</h4>
                </Col>
                <Col md={6}>
                  <div className="fee-desc">$ 100</div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <h4 className="membership-label mt_plan">Plan Fee</h4>
                </Col>
                <Col md={6}>
                  <div className="fee-desc">
                    <Form.Group
                      className="mb-3 input-plan"
                      controlId="formBasicEmail"
                    >
                      <Form.Control type="email" placeholder="$200" />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              <Col md={12}>
                <div className="savings">
                  <span className="savings-text">Savings: </span>
                  <span className="savings-answer">$100</span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {/* EDIT APPOINTMENT MODAL */}
      <div className="pages-modals">
        <ThemeModal
          className="ezfi-modal"
          title={"Edit Appointment"}
          content={<CreateMembership />}
          // footer={<LogoutModalButtons />}
          show={showaddnew}
          onHide={() => setShowAddNew(false)}
        />
      </div>
      {/* END OF EDIT APPOINTMENT MODAL */}

      {/* START OF ADD APPOINTMENT MODAL */}
      <ThemeModal
        className="ezfi-modal"
        title={"Add Appointment"}
        content={<AddAppointment />}
        show={showeditnew}
        onHide={() => setShowEditNew(false)}
      />
      {/* END OF ADD APPOINTMENT MODAL */}
    </div>
  );
};

export default MembershipPlan;
