import {React, useState} from 'react'
import CorporateClientCards from '../../../components/CorporateClientCards'
import PatientCardData from '../Patients/PatientCardData';
import {Col,Row} from "react-bootstrap"

const CorporateClients = () => {
  const [patientdata,setPatientData] = useState(PatientCardData)
  return (
    <Row>
    <CorporateClientCards patientdata={patientdata}/>
    </Row>
    )
}

export default CorporateClients