import { React, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Assets } from "../../../constants/images";
import Form from "react-bootstrap/Form";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
// import {FaEye, FaEyeSlash} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Eye, EyeClose, Tick } from "../../../constants/svg";

const ForgotPass = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const onSubmit = async (data) => {
    const { email } = data;
    if (data.email === "ezfi@tekrevol.com") {
      toast.success("Successfully Submitted !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        history.push("/verifyaccount");
      }, 1500);
    } else {
      toast.error("Invalid Email!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const [values, setValues] = useState({
    amount: "",
    password: "",
    showPassword: false,
  });

  const myHelper = {
    email: {
      required: "Email is Required",
      pattern: "Invalid Email",
    },
    fullname: {
      required: "Full name is Required",
      minLength: "Min length is 25",
      maxLength: "Max length is 255",
    },
    password: {
      required: "Password is Required",
      minLength: "Min length is 8",
      maxLength: "Max length is 255",
    },
    confrmpassword: {
      required: "Confrmpassword is Required",
      minLength: "Min length is 8",
      maxLength: "Max length is 255",
    },
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="formBasicEmail" className="input_main">
        <Form.Label>
          Email Address <span className="form_star">*</span>
        </Form.Label>
        <InputGroup>
          <Form.Control
            className="onboarding-placeholders"
            type="email"
            placeholder="Email address"
            id={"formSignInEmail"}
            {...register("email", {
              required: {
                value: "required",
                message: "Email is required",
              },
              maxLength: {
                value: 255,
                message: "Max length is 255",
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            })}
          />
          <InputGroup.Text id="basic-addon2">
            <Tick />
          </InputGroup.Text>
        </InputGroup>

        {errors.email && (
          <span className={"validation-error pr-5"} role="alert">
            {errors.email.message}
          </span>
        )}
      </Form.Group>

      <div className="main_btn">
        <Button className="main_form_btn" type="submit">
          Continue
        </Button>
      </div>
    </Form>
  );
};

export default ForgotPass;
