import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import StackedChart from "../components/StackedChart";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import {
  DashboardIcon,
  User,
  Role,
  Marketing,
  Payment,
  Financing,
  Appointment,
  Ezpay,
  Ezplan,
  Administrator,
  Content,
  Notification,
  TotalMonth,
  TotalPlans,
  TotalFinance,
  TotalCare,
  TotalPatients,
  Logout,
  SearchIcon,
  DashboardNotification,
  Profilearrow,
  Settings,
  LogoutProfile,
} from "../constants/svg";
import { Assets } from "../constants/images";
import LineGraph from "../components/LineGraph";
import CareGraph from "../components/CareGraph";
import TotalPlansGraph from "../components/TotalPlansGraph";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import Statistics from "../components/Statistics";
import DashboardSidebar from "../components/DashboardSidebar";
import ProfileBox from "../components/ProfileBox";
import { Link, NavLink } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ResponsiveNavbar from "../components/ResponsiveNavbar";
import ThemeModal from "../components/ThemeModal";
import { Button } from "react-bootstrap";
import TopBar from "../components/TopBar";

const DashboardLayout = (props) => {
  return (
    <div>
      <ResponsiveNavbar />
      <Container>
        <div className="dashboard-body top-bar">
          <Row>
            <Col xl={2} className="d-none d-xl-block">
              <DashboardSidebar />
            </Col>
            <Col lg={12} xl={10}>
            <TopBar/>
              <div className="dashboard-heading">{props.pagetitle}</div>
              {props.children}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default DashboardLayout;
