import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
    },
  },
};

const labels = ["M", "T", "W", "T", "F"];

export const data = {

  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [70, 80, 66, 50, 100],
      backgroundColor: "#FFAB00",
      borderRadius: 5,
      barPercentage: 0.4,
      categoryPercentage: 0.4,
    },
    {
      label: "Dataset 2",
      data: [29, 19, 33, 50, 0],
      backgroundColor: "#F3F3F3",
      barPercentage: 0.4,
      categoryPercentage: 0.4,
    },
  ],
};
const EzPayStackedChart = () => {
  return (
    <div>
      <Bar options={options} data={data} />
    </div>
  );
};

export default EzPayStackedChart;
