import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Doughnut, Pie } from "react-chartjs-2";

const PieChart = () => {



const chartColors = [
        "#3B7AFE",
        "#5FBF24",
      ];


  const pieOptions = {

    legend: {
        position: 'bottom'
    },

    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };



  const data = {
    maintainAspectRatio: false,
    responsive: true,
    labels: ["Patient With Membership Plans %", "Patient Without Membership Plans %"],
    datasets: [
      {
        data: [75,25],
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors
      }
    ]
  };

  return (
    <>
      <Pie
        data={data}
        options={pieOptions}
      />
      <div id="legend" />
    </>
  );
};

export default PieChart;
